import React from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation().pathname;
  let fullName = localStorage.getItem("fullName");
  let email = localStorage.getItem("email");
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  return (
    <>
      <nav
        className={`navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row ${location.pathname === "/" || location.pathname === "/forgot-password" ? "d-none" : ""}`}
      >
        <div className="navbar-brand-wrapper d-flex align-items-center">
          <Link className="navbar-brand brand-logo" to="/dashboard">
            <img src="images/logo-e.svg" alt="logo" className="logo-dark" />
          </Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-center flex-grow-1">
          <h5 className="mb-0 font-weight-medium d-none d-lg-flex">
            Welcome Eagle Admin!
          </h5>
          <ul className="navbar-nav navbar-nav-right ml-auto">
            <li className="nav-item dropdown d-none d-xl-inline-flex user-dropdown">
              <Link
                className="nav-link dropdown-toggle"
                id="UserDropdown"
                to="#"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  className="img-xs rounded-circle ml-2"
                  src="favicon.png"
                  alt="Profile_image"
                />{" "}
                <span className="font-weight-normal"> {fullName} </span>
              </Link>
              <div
                className="dropdown-menu dropdown-menu-right navbar-dropdown"
                aria-labelledby="UserDropdown"
              >
                <div className="dropdown-header text-center">
                  <img
                    className="img-md rounded-circle"
                    src="favicon.png"
                    alt="Profile_image"
                  />
                  <p className="mb-1 ">Eagle Group</p>
                  <p className="font-weight-light text-muted mb-0">{email}</p>
                </div>
                <Link className="dropdown-item" to="/change-password">
                  <i className="dropdown-item-icon icon-speech text-primary" />{" "}
                  Change Password
                </Link>
                <Link className="dropdown-item" to="/profile">
                  <i className="dropdown-item-icon icon-user text-primary" /> My
                  Profile
                </Link>
                <Link className="dropdown-item" onClick={handleLogout}>
                  <i className="dropdown-item-icon icon-power text-primary" />
                  Sign Out
                </Link>
              </div>
            </li>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
          >
            <span className="icon-menu" />
          </button>
        </div>
      </nav>
    </>
  );
};

export default Header;
