import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInterceptor";
import { toast } from "react-toastify";
import { ImageLink } from "../constants";
import CodeEditor from "./CodeEditor";

const AddProducts = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const editData = location.state;
  const [data, setData] = useState({
    position: editData?.position || "",
    name: editData?.name || "",
    // weight: editData?.weight || "",
    title: editData?.title || "",
    url: editData?.url || "",
    description: editData?.description || "",
    bannerImage: editData?.bannerImage || "",
    productImage: editData?.productImage || "",
  });

  const [errors, setErrors] = useState({
    position: "",
    name: "",
    // weight: "",
    title: "",
    url: "",
    description: "",
    bannerImage: "",
    productImage: "",
  });

  useEffect(() => {
    if (!editData) {
      fetchMaxPosition();
    }
  }, []);

  const fetchMaxPosition = async () => {
    try {
      const response = await axiosInstance.get(
        "/product/getWithoutPaginationProduct"
      );
      if (response.status === 200) {
        const products = response.data.data;
        if (Array.isArray(products)) {
          const maxPosition = Math.max(
            ...products.map((product) => product.position),
            0
          );

          setData((prevData) => ({
            ...prevData,
            position: maxPosition + 1,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching product:", error);
      toast.error("Failed to fetch product data. Please try again.");
    }
  };

  const handleChange = (e) => {
    const { name, files, value } = e.target;

    if (name === "bannerImage") {
      setData({
        ...data,
        [name]: files.length > 0 ? files[0] : null,
      });
    } else if (name === "productImage") {
      const fileArray = Array.from(files);
      // setData({
      //   ...data,
      //   [name]: fileArray,
      // });
      setData((prevData) => ({
        ...prevData,
        [name]: prevData[name] ? [...prevData[name], ...fileArray] : fileArray,
      }));
    } else {
      setData({ ...data, [name]: value });
    }
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasErrors = false;

    if (!data.position) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        position: "position is required",
      }));
      hasErrors = true;
    }
    if (!data?.name) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "Name is required",
      }));
      hasErrors = true;
    }
    if (!data?.title) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: "Title is required",
      }));
      hasErrors = true;
    }
    if (!data?.description) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        description: "Description is required",
      }));
      hasErrors = true;
    }

    if (!data?.bannerImage) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        bannerImage: "bannerImage is required",
      }));
      hasErrors = true;
    }

    if (!data?.productImage) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        productImage: "Product Image is required",
      }));
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("position", data?.position);
      formData.append("name", data?.name);
      // formData.append("weight", data?.weight);
      formData.append("title", data?.title);
      formData.append("url", data?.url);
      formData.append("description", data?.description);
      if (data?.bannerImage instanceof File) {
        formData.append("bannerImage", data?.bannerImage);
      }
      if (Array.isArray(data?.productImage)) {
        data.productImage.forEach((file) => {
          if (file instanceof File) {
            formData.append("productImage", file);
          }
        });
      }
      const response = await axiosInstance.post(
        `/product/createProduct`,
        formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/product");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
        "Something went wrong. Please try again!"
      );
    }
  };

  const handleDeleteImage = async (index) => {
    if (editData?._id) {
      try {
        const response = await axiosInstance.delete(
          `/product/${editData._id}/image/${index}`
        );
        if (response.status === 200) {
          toast.success(response.data.message);
          const newProductImage = [...data.productImage];
          newProductImage.splice(index, 1);
          setData({ ...data, productImage: newProductImage });
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Failed to delete image. Please try again.");
      }
    } else {
      const newProductImage = [...data.productImage];
      newProductImage.splice(index, 1);
      setData({ ...data, productImage: newProductImage });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("position", data?.position);
      formData.append("name", data?.name);
      // formData.append("weight", data?.weight);
      formData.append("title", data?.title);
      formData.append("url", data?.url);
      formData.append("description", data?.description);
      if (data?.bannerImage instanceof File) {
        formData.append("bannerImage", data?.bannerImage);
      }
      if (Array.isArray(data?.productImage)) {
        data.productImage.forEach((file) => {
          if (file instanceof File) {
            formData.append("productImage", file);
          }
        });
      }
      const response = await axiosInstance.put(
        `/product/updateProduct/${editData?._id}`,
        formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/product");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong. Please try again!!");
      }
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="page-header mt-5 mx-4">
          <h3 className="page-title">
            {editData?._id ? "Edit Product" : "Add Product"}{" "}
          </h3>
        </div>
        <div className="col-md-12 grid-margin stretch-card">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <form
                  className="pt-3"
                  onSubmit={editData?._id ? handleUpdate : handleSubmit}
                >
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Position
                        </label>
                        <input
                          type="number"
                          className="form-control form-control-sm"
                          id="position"
                          name="position"
                          placeholder="Enter slider sort order"
                          value={data.position}
                          onChange={handleChange}
                        />
                        {errors.position && (
                          <p className="text-danger">{errors.position}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="name"
                          name="name"
                          placeholder="Enter a name"
                          value={data?.name}
                          onChange={handleChange}
                        />
                        {errors?.name && (
                          <p className="text-danger">{errors?.name}</p>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-sm-3">
                      <div className="form-group ">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Weight
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="weight"
                          name="weight"
                          placeholder="Enter Weight"
                          value={data?.weight}
                          onChange={handleChange}
                        />
                        {errors?.weight && (
                          <p className="text-danger">{errors?.weight}</p>
                        )}
                      </div>
                    </div> */}
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Title
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="title"
                          name="title"
                          placeholder="Enter Title"
                          value={data?.title}
                          onChange={handleChange}
                        />
                        {errors?.title && (
                          <p className="text-danger">{errors?.title}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Url
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="url"
                          name="url"
                          placeholder="Enter url"
                          value={data?.url}
                          onChange={handleChange}
                        />
                        {errors?.url && (
                          <p className="text-danger">{errors?.url}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <label
                        htmlFor="exampleInputUsername2"
                        className="col-sm-3 col-form-label"
                      >
                        Description
                      </label>
                      <CodeEditor
                        value={data.description}
                        style={{ height: "400px" }}
                        onChange={(value) =>
                          handleChange({
                            target: { name: "description", value },
                          })
                        }
                      />

                      {errors.description && !data?.description?.trim() && (
                        <div className="text-danger">
                          Description is required
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    {/* Banner Image (Single Image) */}
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Product Banner
                        </label>
                        <input
                          type="file"
                          className="form-control form-control-sm"
                          id="bannerImage"
                          name="bannerImage"
                          onChange={handleChange}
                        />
                        {errors?.bannerImage && (
                          <p className="text-danger">{errors?.bannerImage}</p>
                        )}
                        {data?.bannerImage && (
                          <div className="mt-2">
                            {data?.bannerImage instanceof File ? (
                              <img
                                src={URL.createObjectURL(data?.bannerImage)}
                                alt="bannerImage_preview"
                                width={100}
                              />
                            ) : (
                              <img
                                src={ImageLink(data?.bannerImage)}
                                alt="bannerImage_preview"
                                width={100}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* Product Images (Multiple Images) */}
                    <div className="col-sm-8">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Product Images
                        </label>
                        <input
                          type="file"
                          className="form-control form-control-sm"
                          id="productImage"
                          name="productImage"
                          onChange={handleChange}
                          multiple
                        />
                        {errors?.productImage && (
                          <p className="text-danger">{errors?.productImage}</p>
                        )}

                        {/* Preview for multiple product images */}
                        {data?.productImage &&
                          data?.productImage.length > 0 && (
                            <div className="mt-2 d-flex flex-wrap">
                              {data?.productImage?.map((file, index) => (
                                <div
                                  className="border p-2 m-1 position-relative"
                                  key={index}
                                >
                                  {file instanceof File ? (
                                    <img
                                      src={URL.createObjectURL(file)}
                                      alt={`productImage_preview_${index}`}
                                      width={100}
                                      className="mr-2 mb-2"
                                    />
                                  ) : (
                                    <img
                                      src={ImageLink(file)}
                                      alt={`productImage_preview_${index}`}
                                      width={100}
                                      className="mr-2 mb-2"
                                    />
                                  )}
                                  <button
                                    className="position-absolute removeImg"
                                    type="button"
                                    onClick={() => handleDeleteImage(index)}
                                  >
                                    X
                                  </button>
                                </div>
                              ))}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-sm-12">
                      <div className="text-start">
                        <Link to="/product">
                          <button className="btn btn-secondary mr-2">
                            Back
                          </button>
                        </Link>
                        <button type="submit" className="btn btn-success">
                          {editData ? "Update" : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProducts;
