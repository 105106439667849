import React from 'react';
import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';

const SortArrow = ({ isSorted, order }) => {
    if (!isSorted) {
        return (
            <>
                <FaSort className="sorting" />
            </>
        );
    }

    return order === 'asc' ? <FaSortUp className="sorting" /> : <FaSortDown className="sorting" />;
};

export default SortArrow;
