import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInterceptor';
import { toast } from 'react-toastify';

const AddInvestors = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const editData = location.state;
  
  const [investorTypes, setInvestorTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  const [data, setData] = useState({
    investorType: editData?.investorType || '',
    title: editData?.title || '',
    document: null,
  });

  const [errors, setErrors] = useState({
    investorType: '',
    title: '',
    document: '',
  });

  useEffect(() => {
    fetchInvestorTypes();
  }, []);

  const fetchInvestorTypes = async () => {
    try {
      const response = await axiosInstance.get('investor/getInvestorTypes');
      if (response.data.isSuccess) {
        setInvestorTypes(response.data.data);
      } else {
        toast.error(response.data.message || 'Error fetching investor types');
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Something went wrong, please try again!');
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "document") {
      setData({ ...data, [name]: files[0] });
    } else {
      setData({ ...data, [name]: value });
    }
    setErrors({ ...errors, [name]: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasErrors = false;

    if (!data.investorType.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        investorType: 'Investor Type is required'
      }));
      hasErrors = true;
    }
    if (!data.title.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: 'Title is required'
      }));
      hasErrors = true;
    }
    if (!data.document && !editData) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        document: 'Document is required'
      }));
      hasErrors = true;
    }
    if (hasErrors) {
      return;
    }

    const formData = new FormData();
    formData.append('investorType', data.investorType);
    formData.append('title', data.title);
    if (data.document) {
      formData.append('document', data.document);
    }

    try {
      const response = await axiosInstance.post(`investor/createInvestor`, formData);
      if (response.data.isSuccess) {
        toast.success(response.data.message);
        navigator("/investors");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Something went wrong, please try again!');
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('investorType', data.investorType);
    formData.append('title', data.title);
    if (data.document) {
      formData.append('document', data.document);
    }

    try {
      const response = await axiosInstance.put(`investor/updateInvestor/${editData?._id}`, formData);
      if (response.data.isSuccess) {
        toast.success(response.data.message);
        navigator("/investors");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Something went wrong, please try again!');
    }
  };

  if (isLoading) {
    return <div className="content-wrapper">Loading...</div>;
  }

  return (
    <>
      <div className="content-wrapper">
        <div className="page-header mt-5 mx-4">
          <h3 className="page-title">
            {editData ? "Edit Investor" : "Add Investor"}
          </h3>
        </div>
        <div className="col-md-12 grid-margin stretch-card">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <form className="pt-3" onSubmit={editData?._id ? handleUpdate : handleSubmit}>
                  <div className='row'>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="investorType" className="col-form-label">
                          Investor Type
                        </label>
                        <select
                          className="form-control form-control-sm"
                          id="investorType"
                          name="investorType"
                          value={data.investorType}
                          onChange={handleChange}
                        >
                          <option value="">Select Investor Type</option>
                          {investorTypes.map((type) => (
                            <option key={type} value={type}>
                              {type}
                            </option>
                          ))}
                        </select>
                        {errors.investorType && <p className="text-danger">{errors.investorType}</p>}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="title" className="col-form-label">
                          Title
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="title"
                          name="title"
                          placeholder="Enter title"
                          value={data.title}
                          onChange={handleChange}
                        />
                        {errors.title && <p className="text-danger">{errors.title}</p>}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="document" className="col-form-label">
                          Document
                        </label>
                        <input
                          type="file"
                          className="form-control form-control-sm"
                          id="document"
                          name="document"
                          onChange={handleChange}
                        />
                        {errors.document && <p className="text-danger">{errors.document}</p>}
                      </div>
                    </div>
                  </div>
                  <div className='text-start mt-4'>
                    <Link to="/investors">
                      <button className="btn btn-secondary mr-2">
                        Back
                      </button>
                    </Link>
                    <button type="submit" className="btn btn-success">
                      {editData ? "Update" : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddInvestors;