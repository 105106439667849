import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axiosInstance from "../axiosInterceptor";

const Profile = () => {
  const [settingData, setSettingData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`admin/getProfile`);
      const data = response?.data?.profile;
      setSettingData(data);
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="content-wrapper">
      <div className="page-header mt-5 mx-4">
        <h3 className="page-title">Profile </h3>
      </div>
      <div className="col-md-12 grid-margin stretch-card">
        <div className="col-md-8  ">
          <div className="card">
            <div className="card-body">
              <form className="pt-3">
                <div className="form-group row">
                  <label
                    htmlFor="exampleInputUsername2"
                    className="col-sm-3 col-form-label"
                  >
                    Name
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="name"
                      name="name"
                      placeholder="Enter name"
                      value={settingData?.fullName}
                      readOnly
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="exampleInputUsername2"
                    className="col-sm-3 col-form-label"
                  >
                    Email
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="email"
                      className="form-control form-control-sm"
                      id="email"
                      name="email"
                      placeholder="Enter email"
                      value={settingData?.email}
                      readOnly
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
