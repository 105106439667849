import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInterceptor";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Loader from "../Layout/Loader";
import pdfIcon from '../images/icon/pdf.png';
import { ImageLink } from "../constants";

const Investors = () => {
    const [loading, setLoading] = useState(true);
    const [investors, setInvestors] = useState([]);
    const navigator = useNavigate();

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(`investor/getAllInvestorWithoutPagination`);
            const { data } = response?.data;
            setInvestors(data);
        } catch (error) {
            toast.error("Something went wrong. Please try again!!");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return (
            <div>
                <Loader />
            </div>
        );
    }

    const handleDelete = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to delete this investor!",
            icon: "success",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                confirmDelete(id);
            }
        });
    };

    const confirmDelete = async (id) => {
        try {
            const response = await axiosInstance.delete(`investor/deleteInvestor/${id}`);
            if (response?.status === 200) {
                toast.success(response?.data?.message);
                fetchData();
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error) {
            toast.error("Something went wrong. Please try again!!");
        }
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="page-header mt-5">
                    <h3 className="page-title"> Investors </h3>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/add-investor">
                                    <button type="button" className="btn btn-inverse-info btn-fw">
                                        <i className="icon-plus mx-2 mt-2"></i>
                                        Add Investor
                                    </button>
                                </Link>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive border rounded p-1">
                                    <table className="table text-start">
                                        <thead>
                                            <tr>
                                                <th className="font-weight-bold">Investor Type</th>
                                                <th className="font-weight-bold">Title</th>
                                                <th className="font-weight-bold">Document</th>
                                                <th className="font-weight-bold text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-center">
                                            {investors?.length > 0 ? (
                                                investors?.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{item?.investorType} </td>
                                                            <td>{item?.title} </td>
                                                            <td>
                                                                <Link to={ImageLink(item.document)} target="_blank" >
                                                                    <img src={pdfIcon} alt="PDF" style={{ height: "25px", width: "25px", }} />
                                                                </Link>
                                                            </td>
                                                            <td className="text-center">
                                                                <div className="template-demo">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-outline-primary btn-icon-text btn-sm w-40"
                                                                        onClick={() => {
                                                                            navigator("/add-investor", {
                                                                                state: item,
                                                                            });
                                                                        }}
                                                                    >
                                                                        <i className="icon-pencil btn-icon-append mr-1"></i>{" "}
                                                                        Edit
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-outline-danger btn-icon-text btn-sm w-35"
                                                                        onClick={() => handleDelete(item?._id)}
                                                                    >
                                                                        <i className="icon-trash btn-icon-append"></i>{" "}
                                                                        Delete
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan="4">
                                                        <h5>No data found.</h5>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Investors;
