import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInterceptor";
import { toast } from "react-toastify";
import CodeEditor from "./CodeEditor";

const AddSubmenu = () => {
    const navigator = useNavigate();
    const location = useLocation();
    const editData = location.state;
    const parentId = location.pathname.split("/")[2];

    const [data, setData] = useState({
        parentId: parentId || "",
        menuName: editData?.menuName || "",
        title: editData?.title || "",
        metaDescription: editData?.metaDescription || "",
        subTitle: editData?.subTitle || "",
        position: editData?.position || "",
        menuUrl: editData?.menuUrl || "",
        menuType: editData?.menuType || "",
        category: editData?.category?._id || "",
        cms: editData?.cms?._id || "",
        desktopImg: editData?.desktopImg || "",
        mobileImg: editData?.mobileImg || "",
        isActive: editData?.isActive || false,
        showInHeader: editData?.showInHeader || false,
        showInFooter: editData?.showInFooter || false,
    });

    const [errors, setErrors] = useState({
        position: "",
        menuName: "",
        title: "",
        metaDescription: "",
        subtitle: "",
        desktopImg: "",
    });

    const [pageData, setPageData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(`menu/getMenu`);
            const { data } = response.data;
            setPageData(data);
        } catch (error) {
            toast.error("Something went wrong. Please try again!!");
        }
    };

    // useEffect(() => {
    //     if (!editData) {
    //         fetchMaxPosition();
    //     }
    // }, []);

    // const fetchMaxPosition = async () => {
    //     try {
    //         const response = await axiosInstance.get(
    //             "/menu/getWithoutPaginationMenu"
    //         );
    //         if (response.status === 200) {
    //             const menus = response.data.data;
    //             if (Array.isArray(menus)) {
    //                 const maxPosition = Math.max(
    //                     ...menus.filter(menu => menu.parentId !== null).map(menu => menu.position),
    //                     0
    //                 );

    //                 setData((prevData) => ({
    //                     ...prevData,
    //                     position: maxPosition + 1,
    //                 }));
    //             }
    //         }
    //     } catch (error) {
    //         console.error("Error fetching product:", error);
    //         toast.error("Failed to fetch product data. Please try again.");
    //     }
    // };

    useEffect(() => {
        fetchData();
    }, []);

    const [companyData, setCompanyData] = useState([]);
    const [productData, setProductData] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [cmsData, setCmsData] = useState([]);

    // Fetch company data
    const fetchCompanyData = async () => {
        try {
            const response = await axiosInstance.get(
                "company/getWithoutPaginationCompany"
            );
            const { data } = response.data;
            if (!Array.isArray(data)) {
                console.error("Company data is not an array:", data);
                return;
            }
            setCompanyData(data);
        } catch (error) {
            console.error("Error fetching company data:", error);
            toast.error("Failed to fetch company data");
        }
    };

    // Fetch product data
    const fetchProductData = async () => {
        try {
            const response = await axiosInstance.get(
                "product/getWithoutPaginationProduct"
            );
            const { data } = response.data;
            if (!Array.isArray(data)) {
                console.error("Product data is not an array:", data);
                return;
            }
            setProductData(data);
        } catch (error) {
            console.error("Error fetching product data:", error);
            toast.error("Failed to fetch product data");
        }
    };

    // Fetch category data
    const fetchCategoryData = async () => {
        try {
            const response = await axiosInstance.get(
                "reportCategory/getWithoutPaginationCategory"
            );
            const { data } = response.data;
            if (!Array.isArray(data)) {
                console.error("Category data is not an array:", data);
                return;
            }
            setCategoryData(data);
        } catch (error) {
            console.error("Error fetching category data:", error);
            toast.error("Failed to fetch category data");
        }
    };

    // Fetch CMS data
    const fetchCmsData = async () => {
        try {
            const response = await axiosInstance.get("cms/getWithoutPaginationCms");
            const { data } = response.data;
            if (!Array.isArray(data)) {
                console.error("CMS data is not an array:", data);
                return;
            }
            setCmsData(data);
        } catch (error) {
            console.error("Error fetching CMS data:", error);
            toast.error("Failed to fetch CMS data");
        }
    };

    useEffect(() => {
        fetchCompanyData();
        fetchProductData();
        fetchCategoryData();
        fetchCmsData();
    }, []);

    const handleChange = (e) => {
        const { name, files, checked, value } = e.target;
        if (name === "desktopImg" || name === "mobileImg") {
            setData({ ...data, [name]: files.length > 0 ? files[0] : null });
        } else if (
            name === "isActive" ||
            name === "showInFooter" ||
            name === "showInHeader"
        ) {
            setData({ ...data, [name]: checked });
        } else {
            setData({ ...data, [name]: value });
        }
        setErrors({ ...errors, [name]: "" });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasErrors = false;

        if (!data.position) {
            setErrors((prev) => ({ ...prev, position: "Position is required" }));
            hasErrors = true;
        }
        if (!data.menuName) {
            setErrors((prev) => ({ ...prev, menuName: "Menu name is required" }));
            hasErrors = true;
        }
        if (!data.menuType) {
            setErrors((prev) => ({ ...prev, menuType: "Menu type is required" }));
            hasErrors = true;
        }
        if (data.menuType === "CMS" && !data.cms) {
            setErrors((prev) => ({ ...prev, cms: "CMS selection is required" }));
            hasErrors = true;
        }
        if (data.menuType === "Company" && !data.company) {
            setErrors((prev) => ({
                ...prev,
                company: "Company selection is required",
            }));
            hasErrors = true;
        }
        if (data.menuType === "Category" && !data.category) {
            setErrors((prev) => ({
                ...prev,
                category: "Category selection is required",
            }));
            hasErrors = true;
        }
        if (data.menuType === "Product" && !data.product) {
            setErrors((prev) => ({
                ...prev,
                product: "Product selection is required",
            }));
            hasErrors = true;
        }

        if (hasErrors) return;

        try {
            const formData = new FormData();
            if (data.parentId) {
                formData.append("parentId", data.parentId);
            }
            formData.append("menuName", data.menuName);
            formData.append("menuUrl", data.menuUrl);
            formData.append("title", data.title);
            formData.append("keywords", data.keywords);
            formData.append("metaDescription", data.metaDescription);
            formData.append("menuType", data.menuType);
            formData.append("position", data.position);

            if (data.category) formData.append("category", data.category);
            if (data.cms) formData.append("cms", data.cms);
            if (data.company) formData.append("company", data.company);
            if (data.product) formData.append("product", data.product);
            formData.append("isActive", data.isActive);
            formData.append("showInFooter", data.showInFooter);
            formData.append("showInHeader", data.showInHeader);

            const response = await axiosInstance.post(`menu/createMenu`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator(`/menu/${parentId}`);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Something went wrong. Please try again!!");
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            if (data.parentId) {
                formData.append("parentId", data.parentId);
            }
            formData.append("menuName", data.menuName);
            formData.append("menuUrl", data.menuUrl);
            formData.append("title", data.title);
            formData.append("keywords", data.keywords);
            formData.append("metaDescription", data.metaDescription);
            formData.append("menuType", data.menuType);
            formData.append("position", data.position);

            if (data.category) formData.append("category", data.category);
            if (data.cms) formData.append("cms", data.cms);
            if (data.company) formData.append("company", data.company);
            if (data.product) formData.append("product", data.product);
            formData.append("isActive", data.isActive);
            formData.append("showInFooter", data.showInFooter);
            formData.append("showInHeader", data.showInHeader);

            const response = await axiosInstance.put(`menu/updateMenu/${editData?._id}`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator(`/menu/${parentId}`);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Something went wrong. Please try again!!");
        }
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="page-header mt-5 mx-4">
                    <h3 className="page-title">Add SubMenu</h3>
                </div>
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <form
                                    className="pt-3"
                                    onSubmit={editData?._id ? handleUpdate : handleSubmit}
                                >
                                    {/* Base Menu Selection */}
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="position" className="col-form-label">
                                                    Position
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    id="position"
                                                    name="position"
                                                    placeholder="Enter a sort order"
                                                    value={data.position}
                                                    onChange={handleChange}
                                                />
                                                {errors.position && (
                                                    <p className="text-danger">{errors.position}</p>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label
                                                    htmlFor="parentId"
                                                    className="col-form-label"
                                                >
                                                    Base Menu
                                                </label>
                                                <select
                                                    className="form-control form-control-sm"
                                                    id="parentId"
                                                    name="parentId"
                                                    onChange={handleChange}
                                                    value={data.parentId}
                                                >
                                                    <option value="">--- Root content ---</option>
                                                    {pageData?.map((item) => (
                                                        <option key={item._id} value={item._id}>
                                                            {item.menuName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        {/* Menu Type Selection */}
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="menuType" className="col-form-label">
                                                    Menu Type
                                                </label>
                                                <select
                                                    className="form-control form-control-sm"
                                                    id="menuType"
                                                    name="menuType"
                                                    value={data.menuType}
                                                    onChange={handleChange}
                                                >
                                                    <option value="">--- Select menu type ---</option>
                                                    <option value="CMS">CMS</option>
                                                    <option value="Company">Company</option>
                                                    <option value="Product">Product</option>
                                                    <option value="Category">Report Category</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                                {errors.menuType && (
                                                    <p className="text-danger">{errors.menuType}</p>
                                                )}
                                            </div>
                                        </div>

                                        {data.menuType === "CMS" && (
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="cms" className="col-form-label">
                                                        CMS
                                                    </label>
                                                    <select
                                                        className="form-control form-control-sm"
                                                        id="cms"
                                                        name="cms"
                                                        value={data.cms}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">--- Select CMS ---</option>
                                                        {cmsData.map((item) => (
                                                            <option key={item._id} value={item._id}>
                                                                {item.title}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors.cms && (
                                                        <p className="text-danger">{errors.cms}</p>
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                        {data.menuType === "Company" && (
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="company" className="col-form-label">
                                                        Company
                                                    </label>
                                                    <select
                                                        className="form-control form-control-sm"
                                                        id="company"
                                                        name="company"
                                                        value={data.company}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">--- Select Company ---</option>
                                                        {companyData.map((item) => (
                                                            <option key={item._id} value={item._id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors.company && (
                                                        <p className="text-danger">{errors.company}</p>
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                        {data.menuType === "Category" && (
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="category" className="col-form-label">
                                                        Report Category
                                                    </label>
                                                    <select
                                                        className="form-control form-control-sm"
                                                        id="category"
                                                        name="category"
                                                        value={data.category}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">
                                                            --- Select Report category ---
                                                        </option>
                                                        {categoryData.map((item) => (
                                                            <option key={item._id} value={item._id}>
                                                                {item.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors.category && (
                                                        <p className="text-danger">{errors.category}</p>
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                        {data.menuType === "Product" && (
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="product" className="col-form-label">
                                                        Product
                                                    </label>
                                                    <select
                                                        className="form-control form-control-sm"
                                                        id="product"
                                                        name="product"
                                                        value={data.product}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">--- Select Product ---</option>
                                                        {productData.map((item) => (
                                                            <option key={item._id} value={item._id}>
                                                                {item.title}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {errors.product && (
                                                        <p className="text-danger">{errors.product}</p>
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                        {/* Menu Name */}
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="menuName" className="col-form-label">
                                                    Menu Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    id="menuName"
                                                    name="menuName"
                                                    placeholder="Enter a menu name"
                                                    value={data.menuName}
                                                    onChange={handleChange}
                                                />
                                                {errors.menuName && (
                                                    <p className="text-danger">{errors.menuName}</p>
                                                )}
                                            </div>
                                        </div>

                                        {/* Menu URL */}
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="menuUrl" className="col-form-label">
                                                    Menu URL
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    id="menuUrl"
                                                    name="menuUrl"
                                                    placeholder="Enter a menu url"
                                                    value={data.menuUrl}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>


                                        {/* Meta Fields */}
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="title" className="col-form-label">
                                                    Meta Title
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    id="title"
                                                    name="title"
                                                    placeholder="Enter a meta title"
                                                    value={data.title}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="keywords" className="col-form-label">
                                                    Meta Keyword
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    id="keywords"
                                                    name="keywords"
                                                    placeholder="Enter a meta keywords"
                                                    value={data.keywords}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="metaDescription" className="col-form-label">
                                                    Meta Description
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    id="metaDescription"
                                                    name="metaDescription"
                                                    placeholder="Enter a meta description"
                                                    value={data.metaDescription}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>

                                        {/* Toggle Switches */}
                                        <div className="col-sm-6 mt-5">
                                            <div className="form-group row">
                                                <label htmlFor="isActiveSwitch" className="col-sm-3">
                                                    Is Active
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="custom-control custom-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            name="isActive"
                                                            id="isActiveSwitch"
                                                            checked={data.isActive}
                                                            onChange={handleChange}
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="isActiveSwitch"
                                                        >
                                                            {data.isActive ? "Active" : "Deactive"}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label htmlFor="showInHeader" className="col-sm-3">
                                                    Show In Header
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="custom-control custom-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            name="showInHeader"
                                                            id="showInHeader"
                                                            checked={data.showInHeader}
                                                            onChange={handleChange}
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="showInHeader"
                                                        >
                                                            {data.showInHeader ? "Active" : "Deactive"}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label htmlFor="showInFooter" className="col-sm-3">
                                                    Show In Footer
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="custom-control custom-switch">
                                                        <input
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            name="showInFooter"
                                                            id="showInFooter"
                                                            checked={data.showInFooter}
                                                            onChange={handleChange}
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor="showInFooter"
                                                        >
                                                            {data.showInFooter ? "Active" : "Deactive"}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Submit and Back Buttons */}
                                    <div className="text-start mt-4">
                                        <Link to={`/menu/${parentId}`}>
                                            <button className="btn btn-secondary mr-2">Back</button>
                                        </Link>
                                        <button type="submit" className="btn btn-success">
                                            {editData ? "Update" : "Submit"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddSubmenu;
