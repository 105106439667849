import React, { useEffect, useState } from "react";
import axiosInstance from "../axiosInterceptor";
import { toast } from "react-toastify";
import { ImageLink } from "../constants";

const Setting = () => {
  const [settingData, setSettingData] = useState([]);
  const [data, setData] = useState({
    title: "",
    description:"",
    websiteEmail: "",
    phoneNumber: "",
    mobileNumber: "",
    websiteAddress: "",
    websiteLogo: "",
    footerLogo: "",
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`setting/getSettings`);
      const { data } = response.data;
      setSettingData(data);
      setData({ ...data });
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  const handleChange = (e) => {
    const { name, files, value } = e.target;
    if (name === "websiteLogo" || name === "footerLogo") {
      setData({ ...data, [name]: files?.length > 0 ? files[0] : "" });
      setData({ ...data, [name]: files?.length > 0 ? files[0] : null });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      if (data?.websiteLogo instanceof File) {
        formData.append("websiteLogo", data?.websiteLogo);
      }
      if (data?.footerLogo instanceof File) {
        formData.append("footerLogo", data?.footerLogo);
      }
      formData.append("title", data?.title);
      formData.append("description", data?.description)
      formData.append("phoneNumber", data?.phoneNumber);
      formData.append("mobileNumber", data?.mobileNumber);
      formData.append("websiteEmail", data?.websiteEmail);
      formData.append("websiteAddress", data?.websiteAddress);
      const response = await axiosInstance.put(
        `setting/updateSettings`,
        formData
      );
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        fetchData();
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="page-header mt-5 mx-4">
          <h3 className="page-title">Website Settings </h3>
        </div>
        <div className="col-md-12 grid-margin stretch-card">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <form className="pt-3" onSubmit={handleUpdate}>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Website Title
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="title"
                          name="title"
                          placeholder="Enter a title"
                          value={data?.title}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Website Description
                        </label>
                        <textarea
                          type="text"
                          className="form-control form-control-sm"
                          id="description"
                          name="description"
                          placeholder="Enter a Description"
                          value={data?.description}
                          onChange={handleChange}
                          rows={4}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Phone Number
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="phoneNumber"
                          name="phoneNumber"
                          placeholder="Enter a phone"
                          value={data?.phoneNumber}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Mobile Number
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="mobileNumber"
                          name="mobileNumber"
                          placeholder="Enter a mobile"
                          value={data?.mobileNumber}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Website Email
                        </label>
                        <textarea
                          type="text"
                          className="form-control form-control-sm"
                          id="websiteEmail"
                          name="websiteEmail"
                          placeholder="Enter a email"
                          value={data?.websiteEmail}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Website Address
                        </label>
                        <textarea
                          type="text"
                          className="form-control form-control-sm"
                          id="websiteAddress"
                          name="websiteAddress"
                          placeholder="Enter a address"
                          value={data?.websiteAddress}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Website Logo
                        </label>
                        <input
                          type="file"
                          className="form-control form-control-sm"
                          id="websiteLogo"
                          name="websiteLogo"
                          onChange={handleChange}
                        />
                        <div className="mt-4">
                          {data?.websiteLogo instanceof File ? (
                            <img
                              src={URL.createObjectURL(data?.websiteLogo)}
                              alt="logo_preview"
                              height={200}
                              style={{ padding: "20px !important" }}
                            />
                          ) : (
                            <img
                              src={
                                settingData && settingData?.websiteLogo
                                  ? ImageLink(settingData?.websiteLogo)

                                  : ""
                              }
                              alt="logo_preview"
                              className={
                                settingData && settingData?.websiteLogo
                                  ? ""
                                  : "d-none"
                              }
                              height={200}
                              style={{ width:"400px "}}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Footer Logo
                        </label>
                        <input
                          type="file"
                          className="form-control form-control-sm"
                          id="footerLogo"
                          name="footerLogo"
                          onChange={handleChange}
                        />
                        <div className="mt-4">
                          {data?.footerLogo instanceof File ? (
                            <img
                              className="footer-bg-logo"
                              src={URL.createObjectURL(data?.footerLogo)}
                              alt="logo_preview"
                              height={200}
                            />
                          ) : (
                            <img
                              src={
                                settingData && settingData?.footerLogo
                                  ? ImageLink(settingData?.footerLogo)
                                  : ""
                              }
                              alt="logo_preview"
                              className={
                                settingData && settingData?.footerLogo
                                  ? "footer-bg-logo"
                                  : "d-none"
                              }
                              height={200}
                                style={{ width: "400px " }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {settingData && settingData?.websiteLogo && <hr />}
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="text-start mt-4">
                        <button type="submit" className="btn btn-success">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Setting;
