import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInterceptor';
import { toast } from 'react-toastify';
import CodeEditor from './CodeEditor';

const AddCms = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const editData = location.state;

    const [data, setData] = useState({
        title: editData?.title || '',
        description: editData?.description || '', 
        url: editData?.url || '',
        metaTitle: editData?.metaTitle || '',
        metaDescription: editData?.metaDescription || '', 
        metaKeyword: editData?.metaKeyword || '',
    });

    const [errors, setErrors] = useState({
        title: '',
        description: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const validateForm = () => {
        let formErrors = {};
        if (!data.title.trim()) {
            formErrors.title = 'Title is required';
        }
        if (!data.description.trim()) {
            formErrors.description = 'Description is required';
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            const formData = new FormData();
            formData.append("title", data.title);
            formData.append("description", data.description);
            formData.append("url", data.url);
            formData.append("metaTitle", data.metaTitle);
            formData.append("metaKeyword", data.metaKeyword);
            formData.append("metaDescription", data.metaDescription);

            const response = await axiosInstance.post(`cms/createCms`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigate("/cms");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!');
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        try {
            const formData = new FormData();
            formData.append("title", data.title);
            formData.append("description", data.description);
            formData.append("url", data.url);
            formData.append("metaTitle", data.metaTitle);
            formData.append("metaKeyword", data.metaKeyword);
            formData.append("metaDescription", data.metaDescription);

            const response = await axiosInstance.put(`cms/updateCms/${editData?._id}`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigate("/cms");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!');
        }
    };

    return (
        <div className="content-wrapper">
            <div className="page-header mt-5 mx-4">
                <h3 className="page-title">{editData ? 'Edit CMS' : 'Add CMS'}</h3>
            </div>
            <div className="col-md-12 grid-margin stretch-card">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <form className="pt-3" onSubmit={editData ? handleUpdate : handleSubmit}>
                                <div className="row">
                                    {/* Title */}
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="title" className="col-form-label">Title</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="title"
                                                name="title"
                                                placeholder="Enter title"
                                                value={data.title}
                                                onChange={handleChange}
                                            />
                                            {errors.title && <p className="text-danger">{errors.title}</p>}
                                        </div>
                                    </div>

                                    {/* URL */}
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="url" className="col-form-label">URL</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="url"
                                                name="url"
                                                placeholder="Enter URL"
                                                value={data.url}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    {/* Meta Title */}
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="metaTitle" className="col-form-label">Meta Title</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="metaTitle"
                                                name="metaTitle"
                                                placeholder="Enter meta title"
                                                value={data.metaTitle}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    {/* Meta Keyword */}
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="metaKeyword" className="col-form-label">Meta Keyword</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="metaKeyword"
                                                name="metaKeyword"
                                                placeholder="Enter meta keyword"
                                                value={data.metaKeyword}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    {/* Meta Description */}
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="metaDescription" className="col-form-label">Meta Description</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-sm"
                                                id="metaDescription"
                                                name="metaDescription"
                                                placeholder="Enter meta description"
                                                value={data.metaDescription}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>

                                    {/* Description */}
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="description" className="col-form-label">Description</label>
                                            <CodeEditor
                                                value={data.description}
                                                style={{ height: '400px' }}
                                                onChange={(value) => handleChange({ target: { name: 'description', value } })}
                                            />
                                            {errors.description && !data.description.trim() && (
                                                <div className="text-danger">{errors.description}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {/* Buttons */}
                                <div className="text-start mt-4">
                                    <Link to="/cms">
                                        <button className="btn btn-secondary mr-2">Back</button>
                                    </Link>
                                    <button type="submit" className="btn btn-success">
                                        {editData ? "Update" : "Submit"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddCms;
