import React, { useEffect, useState } from "react";
import axiosInstance from "../axiosInterceptor";
import { toast } from "react-toastify";
import CodeEditor from './CodeEditor';

const HomeSetting = () => {
    const [data, setData] = useState({
        product: { title: "", subtitles: "" },
        excellence: { title: "", subtitles: "" },
        company: { title: "", subtitles: "" },
        imgSection: ''
    });

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(`homeSettings/getHomeSettings`);
            const { data: settings } = response.data;
            setData({
                product: settings.product || { title: "", subtitles: "" },
                excellence: settings.excellence || { title: "", subtitles: "" },
                company: settings.company || { title: "", subtitles: "" },
                imgSection: settings.imgSection || ''
            });
        } catch (error) {
            toast.error("Something went wrong. Please try again!!");
        }
    };

    const handleChange = (e, key, field) => {
        const { value } = e.target;
        setData((prevState) => ({
            ...prevState,
            [key]: { ...prevState[key], [field]: value },
        }));
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.put(`homeSettings/updateHomeSettings`, data);
            if (response?.status === 200) {
                toast.success(response?.data?.message);
                fetchData();
            } else {
                toast.error(response?.data?.message);
            }
        } catch (error) {
            toast.error("Something went wrong. Please try again!!");
        }
    };

    return (
        <div className="content-wrapper">
            <div className="page-header mt-5 mx-4">
                <h3 className="page-title">Home Settings</h3>
            </div>
            <div className="col-md-12 grid-margin stretch-card">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <form className="pt-3" onSubmit={handleUpdate}>
                                {["product", "excellence", "company"].map((key) => (
                                    <div key={key} className="mb-5">
                                        <h4 className="mb-1">{key.charAt(0).toUpperCase() + key.slice(1)}</h4>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Title</label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder={`Enter ${key} title`}
                                                        value={data[key]?.title}
                                                        onChange={(e) => handleChange(e, key, "title")}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label className="col-form-label">Subtitles</label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        placeholder={`Enter ${key} subtitles`}
                                                        value={data[key]?.subtitles}
                                                        onChange={(e) => handleChange(e, key, "subtitles")}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="description" className="col-form-label"><h4>Image Section</h4></label>
                                        <CodeEditor
                                            value={data.imgSection}
                                            style={{ height: '400px' }}
                                            onChange={(value) =>
                                                setData((prevState) => ({
                                                    ...prevState,
                                                    imgSection: value, 
                                                }))
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="text-start mt-4">
                                            <button type="submit" className="btn btn-success">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeSetting;
