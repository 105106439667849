import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInterceptor";
import { toast } from "react-toastify";
import { ImageLink } from "../constants";
import CodeEditor from "./CodeEditor";

const AddCompanies = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const editData = location.state;
  const [data, setData] = useState({
    position: editData?.position || "",
    name: editData?.name || "",
    title: editData?.title || "",
    address: editData?.address || "",
    companyUrl: editData?.companyUrl || "",
    longDescription: editData?.longDescription || "",
    shortDescription: editData?.shortDescription || "",
    desktopImg: editData?.desktopImg || "",
    backgroundImg: editData?.backgroundImg || "",
    bannerImg: editData?.bannerImg || "",
    isActive: editData?.isActive || false,
    showInCompany: editData?.showInCompany || false,
    showInHome: editData?.showInHome || false,
  });

  const [errors, setErrors] = useState({
    position: "",
    name: "",
    title: "",
    address: "",
    shortDescription: "",
    bannerImg: "",
    desktopImg: "",
    backgroundImg: "",
  });

  useEffect(() => {
    if (!editData) {
      fetchMaxPosition();
    }
  }, []);

  const fetchMaxPosition = async () => {
    try {
      const response = await axiosInstance.get('/company/getWithoutPaginationCompany');
      if (response.status === 200) {
        const companies = response.data.data;
        if (Array.isArray(companies)) {
          const maxPosition = Math.max(...companies.map(company => company.position), 0);

          setData(prevData => ({
            ...prevData,
            position: maxPosition + 1
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching banners:", error);
      toast.error("Failed to fetch banner data. Please try again.");
    }
  };

  const handleChange = (e) => {
    const { name, files, checked, value } = e.target;
    if (name === "desktopImg") {
      const fileArray = Array.from(files);
      // setData({
      //   ...data,
      //   [name]: fileArray,
      // });
      setData((prevData) => ({
        ...prevData,
        [name]: prevData[name] ? [...prevData[name], ...fileArray] : fileArray,
      }));
    } else if (name === "backgroundImg") {
      setData({ ...data, [name]: files.length > 0 ? files[0] : null });
    } else if (name === "bannerImg") {
      setData({ ...data, [name]: files.length > 0 ? files[0] : null });
    } else if (name === "isActive") {
      setData({ ...data, [name]: checked });
    } else if (name === "showInHome") {
      setData({ ...data, [name]: checked });
    } else if (name === "showInCompany") {
      setData({ ...data, [name]: checked });
    } else {
      setData({ ...data, [name]: value });
    }
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasErrors = false;

    // Validation
    if (!data.position) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        position: "Position is required",
      }));
      hasErrors = true;
    }
    if (!data.name.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "Name is required",
      }));
      hasErrors = true;
    }
    if (!data.title.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: "Title is required",
      }));
      hasErrors = true;
    }
    if (!data.address.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: "Address is required",
      }));
      hasErrors = true;
    }

    if (!data.shortDescription.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        shortDescription: "Short Description is required",
      }));
      hasErrors = true;
    }
    if (!data.desktopImg) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        desktopImg: "Desktop img is required",
      }));
      hasErrors = true;
    }
    if (!data.backgroundImg) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        backgroundImg: "Background img is required",
      }));
      if (!data.bannerImg) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          bannerImg: "Banner img is required",
        }));
      }
      hasErrors = true;
    }


    if (hasErrors) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("position", data.position);
      formData.append("name", data?.name);
      formData.append("title", data?.title);
      formData.append("address", data?.address);
      formData.append("companyUrl", data?.companyUrl);
      formData.append("longDescription", data?.longDescription);
      formData.append("shortDescription", data?.shortDescription);

      if (Array.isArray(data?.desktopImg)) {
        data.desktopImg.forEach((file) => {
          if (file instanceof File) {
            formData.append("desktopImg", file);
          }
        });
      }

      if (data.backgroundImg instanceof File) {
        formData.append("backgroundImg", data.backgroundImg);
      }
      if (data.bannerImg instanceof File) {
        formData.append("bannerImg", data.bannerImg);
      }
      formData.append("isActive", data.isActive);
      formData.append("showInHome", data.showInHome);
      formData.append("showInCompany", data.showInCompany);

      const response = await axiosInstance.post(
        `/company/createCompany`,
        formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/companies");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong. Please try again!!");
      }
    }
  };


  const handleDeleteImage = async (index) => {
    if (editData?._id) {
      try {
        const response = await axiosInstance.delete(`/company/${editData._id}/image/${index}`);
        if (response.status === 200) {
          toast.success(response.data.message);
          const newDesktopImg = [...data.desktopImg];
          newDesktopImg.splice(index, 1);
          setData({ ...data, desktopImg: newDesktopImg });
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Failed to delete image. Please try again.");
      }
    } else {
      const newDesktopImg = [...data.desktopImg];
      newDesktopImg.splice(index, 1);
      setData({ ...data, desktopImg: newDesktopImg });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("position", data.position);
      formData.append("name", data.name);
      formData.append("title", data.title);
      formData.append("address", data.address);
      formData.append("companyUrl", data.companyUrl);
      formData.append("longDescription", data.longDescription);
      formData.append("shortDescription", data.shortDescription);
      formData.append("isActive", data.isActive);
      formData.append("showInHome", data.showInHome);
      formData.append("showInCompany", data.showInCompany);

      // Handle desktop images
      if (Array.isArray(data.desktopImg)) {
        data.desktopImg.forEach((img, index) => {
          if (img instanceof File) {
            formData.append(`desktopImg`, img);
          }
        });
      }

      if (data.backgroundImg instanceof File) {
        formData.append("backgroundImg", data.backgroundImg);
      }
      if (data.bannerImg instanceof File) {
        formData.append("bannerImg", data.bannerImg);
      }

      const response = await axiosInstance.put(
        `/company/updateCompany/${editData._id}`,
        formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/companies");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong. Please try again!!");
      }
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="page-header mt-5 mx-4">
          <h3 className="page-title">
            {editData?._id ? "Edit Company" : "Add Company"}
          </h3>
        </div>
        <div className="col-md-12 grid-margin stretch-card">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <form
                  className="pt-3"
                  onSubmit={editData?._id ? handleUpdate : handleSubmit}
                >
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Position
                        </label>
                        <input
                          type="number"
                          className="form-control form-control-sm"
                          id="position"
                          name="position"
                          placeholder="Enter slider sort order"
                          value={data.position}
                          onChange={handleChange}
                        />
                        {errors.position && (
                          <p className="text-danger">{errors.position}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="name"
                          name="name"
                          placeholder="Enter a name"
                          value={data?.name}
                          onChange={handleChange}
                        />
                        {errors?.title && (
                          <p className="text-danger">{errors?.name}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Title
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="title"
                          name="title"
                          placeholder="Enter a title"
                          value={data?.title}
                          onChange={handleChange}
                        />
                        {errors?.title && (
                          <p className="text-danger">{errors?.title}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label
                          htmlFor="companyUrl"
                          className="col-form-label"
                        >
                          Company URL
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="companyUrl"
                          name="companyUrl"
                          placeholder="Enter company URL"
                          value={data.companyUrl}
                          onChange={handleChange}
                        />
                        {errors.companyUrl && (
                          <p className="text-danger">{errors.companyUrl}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div className="form-group">
                        <label
                          htmlFor="address"
                          className="col-form-label"
                        >
                          Company Address
                        </label>

                        <textarea className="form-control"
                          id="address" name="address"
                          placeholder="Enter company address"
                          rows="3" value={data.address}
                          onChange={handleChange}
                        >
                        </textarea>
                        {errors?.address && (
                          <p className="text-danger">{errors?.address}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label
                          htmlFor="shortDescription"
                          className="col-form-label"
                        >
                          Short Description
                        </label>
                        <CodeEditor value={data.shortDescription} style={{ height: '400px' }} onChange={(value) => handleChange({ target: { name: 'shortDescription', value } })} />

                        {errors?.shortDescription && (
                          <p className="text-danger">{errors?.shortDescription}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label
                          htmlFor="longDescription"
                          className="col-form-label"
                        >
                          Long Description
                        </label>
                        <CodeEditor value={data.longDescription} style={{ height: '400px' }} onChange={(value) => handleChange({ target: { name: 'longDescription', value } })} />

                      </div>
                    </div>
                  </div>

                  <div className="row">

                    {/* Background img for home page */}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Background Image
                        </label>
                        <input
                          type="file"
                          className="form-control form-control-sm"
                          id="backgroundImg"
                          name="backgroundImg"
                          onChange={handleChange}
                        />
                        {errors?.backgroundImg && (
                          <p className="text-danger">{errors?.backgroundImg}</p>
                        )}
                        <div>
                          {data?.backgroundImg && (
                            <div className="mt-2">
                              {data.backgroundImg instanceof File ? (
                                <img
                                  src={URL.createObjectURL(data?.backgroundImg)}
                                  alt="backgroundImg_preview"
                                  width={100}
                                />
                              ) : (
                                <img
                                  src={ImageLink(data?.backgroundImg)}
                                  alt="backgroundImg_preview"
                                  width={100}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Banner img for showing in every page at top */}
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label
                          htmlFor="bannerImg"
                          className="col-form-label"
                        >
                          Banner Image
                        </label>
                        <input
                          type="file"
                          className="form-control form-control-sm"
                          id="bannerImg"
                          name="bannerImg"
                          onChange={handleChange}
                        />
                        {errors?.bannerImg && (
                          <p className="text-danger">{errors?.bannerImg}</p>
                        )}
                        <div>
                          {data?.bannerImg && (
                            <div className="mt-2">
                              {data.bannerImg instanceof File ? (
                                <img
                                  src={URL.createObjectURL(data?.bannerImg)}
                                  alt="bannerImg_preview"
                                  width={100}
                                />
                              ) : (
                                <img
                                  src={ImageLink(data?.bannerImg)}
                                  alt="bannerImg_preview"
                                  width={100}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Desktop img for showing multiple img in company page and in infrastructure page*/}
                    <div className="col-sm-6">

                      <div className="form-group">
                        <label htmlFor="exampleInputUsername2" className="col-form-label">
                          Desktop Images
                        </label>
                        <input
                          type="file"
                          className="form-control form-control-sm"
                          id="desktopImg"
                          name="desktopImg"
                          onChange={handleChange}
                          multiple
                        />
                        {errors?.desktopImg && (
                          <p className="text-danger">{errors?.desktopImg}</p>
                        )}

                        {data?.desktopImg && data?.desktopImg.length > 0 && (
                          <div className="mt-2 d-flex flex-wrap">
                            {data?.desktopImg?.map((file, index) => (

                              <div className="border p-2 m-1 position-relative" key={index}>
                                {
                                  file instanceof File ? (
                                    <img
                                      src={URL.createObjectURL(file)}
                                      alt={`desktopImg_preview_${index}`}
                                      width={100}
                                      className="mr-2 mb-2"
                                    />
                                  ) : (
                                    <img
                                      src={ImageLink(file)}
                                      alt={`desktopImg_preview_${index}`}
                                      width={100}
                                      className="mr-2 mb-2"
                                    />
                                  )}
                                <button
                                  className="position-absolute removeImg"
                                  type="button"
                                  onClick={() => handleDeleteImage(index)}
                                >
                                  X
                                </button>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6 mt-5 pl-4">
                      <div className="form-group row">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            name="showInCompany"
                            id="showInCompanySwitch"
                            checked={data?.showInCompany}
                            onChange={(e) => handleChange(e)}
                          />
                          <label
                            className="custom-control-label "
                            for="showInCompanySwitch"
                          >
                            {data?.showInCompany === true ? "Show Desktop Images In Company Page" : "Don't Show Desktop Images In Company Page"}
                          </label>
                        </div>

                      </div>
                    </div>


                  </div>
                  <div className="col-sm-6 mt-5 pl-4">
                    <div className="form-group row">
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          name="showInHome"
                          id="showInHomeSwitch"
                          checked={data?.showInHome}
                          onChange={(e) => handleChange(e)}
                        />
                        <label
                          className="custom-control-label "
                          for="showInHomeSwitch"
                        >
                          {data?.showInHome === true ? "Show In Home" : "Don't Show In Home"}
                        </label>
                      </div>

                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-sm-12">
                      <div className="text-start">
                        <Link to="/companies">
                          <button className="btn btn-secondary mr-2">
                            Back{" "}
                          </button>
                        </Link>
                        <button type="submit" className="btn btn-success">
                          {editData?._id ? "Update" : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCompanies;
