import React, { useState } from "react";
import { FaCircle, FaRegCircle } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation().pathname;
  const [selectedItem, setSelectedItem] = useState(null);
  const [masterActive, setMasterActive] = useState(false);

  const handleItemClick = (itemName) => {
    setSelectedItem(itemName === selectedItem ? null : itemName);
  };
  return (
    <>
      <nav
        className={`sidebar sidebar-offcanvas ${location.pathname === "/" || location.pathname === "/forgot-password" ? "d-none" : ""}`}
        id="sidebar"
      >
        <ul className="nav">
          <li
            className={`nav-item nav-profile ${location === "/dashboard" && "active"}`}
          >
            <Link className="nav-link" to="/dashboard">
              <span className="menu-title">Dashboard</span>
              <i className="icon-screen-desktop menu-icon " />
            </Link>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              data-toggle="collapse"
              href="#ui-basic"
              aria-expanded="false"
              aria-controls="ui-basic"
              onClick={() => setMasterActive(!masterActive)}
            >
              <span className="menu-title">Master Tables</span>
              <i className={masterActive ? "icon-arrow-right menu-icon" : "icon-arrow-down menu-icon"} />
            </a>
            <div className="collapse" id="ui-basic">
              <ul className="nav flex-column sub-menu">
                {/* <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/about"
                    onClick={() => handleItemClick("About")}
                  >
                    {selectedItem === "About" ? (
                      <FaCircle className="circle-icon mx-3" />
                    ) : (
                      <FaRegCircle className="mx-3" />
                    )}
                    About
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/milestone"
                    onClick={() => handleItemClick("Milestone")}
                  >
                    {selectedItem === "Milestone" ? (
                      <FaCircle className="circle-icon mx-3" />
                    ) : (
                      <FaRegCircle className="mx-3" />
                    )}
                    Milestone
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/counter"
                    onClick={() => handleItemClick("Counter")}
                  >
                    {selectedItem === "Counter" ? (
                      <FaCircle className="circle-icon mx-3" />
                    ) : (
                      <FaRegCircle className="mx-3" />
                    )}
                    Counter
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/excellence"
                    onClick={() => handleItemClick("Excellence")}
                  >
                    {selectedItem === "Excellence" ? (
                      <FaCircle className="circle-icon mx-3" />
                    ) : (
                      <FaRegCircle className="mx-3" />
                    )}
                    Excellence
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/page-content"
                    onClick={() => handleItemClick("PageContent")}
                  >
                    {selectedItem === "PageContent" ? (
                      <FaCircle className="circle-icon mx-3" />
                    ) : (
                      <FaRegCircle className="mx-3" />
                    )}
                    Page Content
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/our-customers"
                    onClick={() => handleItemClick("OurCustomers")}
                  >
                    {selectedItem === "OurCustomers" ? (
                      <FaCircle className="circle-icon mx-3" />
                    ) : (
                      <FaRegCircle className="mx-3" />
                    )}
                    Our Customers
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/companies"
                    onClick={() => handleItemClick("Companies")}
                  >
                    {selectedItem === "Companies" ? (
                      <FaCircle className="circle-icon mx-3" />
                    ) : (
                      <FaRegCircle className="mx-3" />
                    )}
                    Companies
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/management"
                    onClick={() => handleItemClick("Management")}
                  >
                    {selectedItem === "Management" ? (
                      <FaCircle className="circle-icon mx-3" />
                    ) : (
                      <FaRegCircle className="mx-3" />
                    )}
                    Management
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/investors"
                    onClick={() => handleItemClick("Investors")}
                  >
                    {selectedItem === "Investors" ? (
                      <FaCircle className="circle-icon mx-3" />
                    ) : (
                      <FaRegCircle className="mx-3" />
                    )}
                    Investors
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/report-category"
                    onClick={() => handleItemClick("ReportCategory")}
                  >
                    {selectedItem === "ReportCategory" ? (
                      <FaCircle className="circle-icon mx-3" />
                    ) : (
                      <FaRegCircle className="mx-3" />
                    )}
                    Report Category
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <li className={`nav-item  ${location === "/cms" && "active"}`}>
            <Link className="nav-link" to="/cms">
              <span className="menu-title">CMS</span>
              <i className="icon-docs menu-icon" />
            </Link>
          </li>
          <li className={`nav-item  ${location === "/menu" && "active"}`}>
            <Link className="nav-link" to="/menu">
              <span className="menu-title">Menu</span>
              <i className="icon-menu menu-icon" />
            </Link>
          </li>
          <li className={`nav-item  ${location === "/slider" && "active"}`}>
            <Link className="nav-link" to="/slider">
              <span className="menu-title">Home banners</span>
              <i className="icon-home menu-icon" />
            </Link>
          </li>
          <li className={`nav-item  ${location === "/home-settings" && "active"}`}>
            <Link className="nav-link" to="/home-settings">
              <span className="menu-title">Home Settings</span>
              <i className="icon-settings menu-icon" />
            </Link>
          </li>
          <li className={`nav-item  ${location === "/banner" && "active"}`}>
            <Link className="nav-link" to="/banner">
              <span className="menu-title">Page wise banners</span>
              <i className="icon-picture menu-icon" />
            </Link>
          </li>
          <li className={`nav-item  ${location === "/product" && "active"}`}>
            <Link className="nav-link" to="/product">
              <span className="menu-title">Products</span>
              <i className="icon-layers menu-icon" />
            </Link>
          </li>
          {/* <li className={`nav-item  ${location === "/enquiries" && "active"}`}>
            <Link className="nav-link" to="/enquiries">
              <span className="menu-title">Enquiries</span>
              <i className="icon-envelope menu-icon" />
            </Link>
          </li> */}
          <li className="nav-item">
            <a
              className="nav-link"
              data-toggle="collapse"
              href="#ui-basic1"
              aria-expanded="false"
              aria-controls="ui-basic1"
            >
              <span className="menu-title">Settings</span>
              <i className="icon-settings menu-icon" />
            </a>
            <div className="collapse" id="ui-basic1">
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/social-media"
                    onClick={() => handleItemClick("Social Media")}
                  >
                    {selectedItem === "Social Media" ? (
                      <FaCircle className="circle-icon mx-3" />
                    ) : (
                      <FaRegCircle className="mx-3" />
                    )}
                    Social Media
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/settings"
                    onClick={() => handleItemClick("Website Settings")}
                  >
                    {selectedItem === "Website Settings" ? (
                      <FaCircle className="circle-icon mx-3" />
                    ) : (
                      <FaRegCircle className="mx-3" />
                    )}
                    Website Settings
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/email-configuration"
                    onClick={() => handleItemClick("Email Configuration")}
                  >
                    {selectedItem === "Email Configuration" ? (
                      <FaCircle className="circle-icon mx-3" />
                    ) : (
                      <FaRegCircle className="mx-3" />
                    )}
                    Email Configuration
                  </Link>
                </li> */}
              </ul>
            </div>
          </li>
          <li className={`nav-item  ${location === "/sitemap" && "active"}`}>
            <Link className="nav-link" to="/sitemap">
              <span className="menu-title">Sitemap</span>
              <i className="icon-organization menu-icon" />

            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
