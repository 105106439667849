import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInterceptor";
import { toast } from "react-toastify";

const AddMileStone = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const editData = location.state;
  const [data, setData] = useState({
    position: editData?.position || "",
    year: editData?.year || "",
    description: editData?.description || "",
  });

  useEffect(() => {
    if (!editData) {
      fetchMaxPosition();
    }
  }, []);

  const fetchMaxPosition = async () => {
    try {
      const response = await axiosInstance.get(
        "/milestone/getWithoutPaginationMilestone"
      );
      if (response.status === 200) {
        const milestones = response.data;
        if (Array.isArray(milestones)) {
          const maxPosition = Math.max(
            ...milestones.map((milestone) => milestone.position),
            0
          );

          setData((prevData) => ({
            ...prevData,
            position: maxPosition + 1,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching banners:", error);
      toast.error("Failed to fetch banner data. Please try again.");
    }
  };

  const [errors, setErrors] = useState({
    position: "",
    year: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasErrors = false;

    if (!data?.position) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        position: "Position is required",
      }));
      hasErrors = true;
    }
    if (!data?.year.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        year: "Year is required",
      }));
      hasErrors = true;
    }
    if (!data?.description) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        description: "Description is required",
      }));
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("position", data?.position);
      formData.append("year", data?.year);
      formData.append("description", data.description);

      const response = await axiosInstance.post(
        `/milestone/createMilestone`,
        formData
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/milestone");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong. Please try again!!");
      }
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("position", data?.position);
      formData.append("year", data?.year);
      formData.append("description", data.description);

      const response = await axiosInstance.put(
        `/milestone/updateMilestone/${editData?._id}`,
        formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        await navigator("/milestone");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong. Please try again!!");
      }
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="page-header mt-5 mx-4">
          <h3 className="page-title">
            {editData?._id ? "Edit Milestone" : "Add Milestone"}{" "}
          </h3>
        </div>
        <div className="col-md-12 grid-margin stretch-card">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <form
                  className="pt-3"
                  onSubmit={editData?._id ? handleUpdate : handleSubmit}
                >
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Position
                        </label>
                        <input
                          type="number"
                          className="form-control form-control-sm"
                          id="position"
                          name="position"
                          placeholder="Enter slider sort order"
                          value={data.position}
                          onChange={handleChange}
                        />
                        {errors.position && (
                          <p className="text-danger">{errors.position}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Year
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="year"
                          name="year"
                          placeholder="Enter a year"
                          value={data?.year}
                          onChange={handleChange}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9-]/g,
                              ""
                            );
                          }}
                        />
                        {errors?.year && (
                          <p className="text-danger">{errors?.year}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Description
                        </label>
                        <textarea
                          type="text"
                          className="form-control form-control-sm"
                          id="description"
                          name="description"
                          placeholder="Enter description"
                          value={data?.description}
                          onChange={handleChange}
                          style={{ height: "150px" }}
                        />
                        {errors?.description && (
                          <p className="text-danger">{errors?.description}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-sm-12">
                      <div className="text-start">
                        <Link to="/milestone">
                          <button className="btn btn-secondary mr-2">
                            Back{" "}
                          </button>
                        </Link>
                        <button type="submit" className="btn btn-success">
                          {editData?._id ? "Update" : "Submit"}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddMileStone;
