import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInterceptor";
import { toast } from "react-toastify";
import { ImageLink } from "../constants";

const AddExcellence = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const editData = location.state;
  const [data, setData] = useState({
    position: editData?.position || "",
    name: editData?.name || "",
    icon: editData?.icon || "",
    description: editData?.description || "",
  });

  const [errors, setErrors] = useState({
    position: "",
    name: "",
    icon: "",
    description: "",
  });

  useEffect(() => {
    if (!editData) {
      fetchMaxPosition();
    }
  }, []);

  const fetchMaxPosition = async () => {
    try {
      const response = await axiosInstance.get('/excellence/getWithoutPaginationExcellence');
      if (response.status === 200) {
        const excellences = response.data.data;
        if (Array.isArray(excellences)) {
          const maxPosition = Math.max(...excellences.map(excellence => excellence.position), 0);

          setData(prevData => ({
            ...prevData,
            position: maxPosition + 1
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching excellence:", error);
      toast.error("Failed to fetch Excellence data. Please try again.");
    }
  };

  const handleChange = (e) => {
    const { name, files, value } = e.target;
    if (name === "icon") {
      setData({ ...data, [name]: files.length > 0 ? files[0] : null });
    } else {
      setData({ ...data, [name]: value });
    }
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasErrors = false;

    if (!data.position) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        position: "Sort Order is required",
      }));
      hasErrors = true;
    }
    if (!data?.name) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "Name is required",
      }));
      hasErrors = true;
    }
    if (!data?.icon) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        icon: "Icon is required",
      }));
      hasErrors = true;
    }
    if (!data?.description) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        description: "Description is required",
      }));
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("position", data?.position);
      formData.append("name", data?.name);
      formData.append("description", data?.description);
      if (data?.icon instanceof File) {
        formData.append("icon", data?.icon);
      }
      const response = await axiosInstance.post(
        `/excellence/createExcellence`,
        formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/excellence");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong. Please try again!");
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("position", data?.position);
      formData.append("name", data?.name);
      formData.append("description", data?.description);
      if (data?.icon instanceof File) {
        formData.append("icon", data?.icon);
      }
      const response = await axiosInstance.put(
        `/excellence/updateExcellence/${editData?._id}`,
        formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/excellence");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="page-header mt-5 mx-4">
          <h3 className="page-title">{editData?._id ? "Edit Excellence" : "Add Excellence"} </h3>
        </div>
        <div className="col-md-12 grid-margin stretch-card">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <form
                  className="pt-3"
                  onSubmit={editData?._id ? handleUpdate : handleSubmit}
                >
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Position
                        </label>
                        <input
                          type="number"
                          className="form-control form-control-sm"
                          id="position"
                          name="position"
                          placeholder="Enter slider sort order"
                          value={data.position}
                          onChange={handleChange}
                        />
                        {errors.position && <p className="text-danger">{errors.position}</p>}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="name"
                          name="name"
                          placeholder="Enter a name"
                          value={data?.name}
                          onChange={handleChange}
                        />
                        {errors?.name && (
                          <p className="text-danger">{errors?.name}</p>
                        )}
                      </div>
                    </div>

                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label
                          htmlFor="description"
                          className="col-form-label"
                        >
                          Description
                        </label>
                        <textarea
                          type="text"
                          className="form-control form-control-sm"
                          rows="5"
                          id="description"
                          name="description"
                          placeholder="Enter a description"
                          value={data?.description}
                          onChange={handleChange}
                        />
                        {errors?.description && (
                          <p className="text-danger">{errors?.description}</p>
                        )}
                      </div>
                    </div>

                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Icon
                        </label>
                        <input
                          type="file"
                          className="form-control form-control-sm"
                          id="icon"
                          name="icon"
                          onChange={handleChange}
                        />
                        {errors?.icon && (
                          <p className="text-danger">{errors?.icon}</p>
                        )}
                        {data?.icon && (
                          <div className="mt-2">
                            {data.icon instanceof File ? (
                              <img
                                src={URL.createObjectURL(data.icon)}
                                alt="icon_preview"
                                width={100}
                              />
                            ) : (
                              <img
                                src={ImageLink(data.icon)}
                                alt="icon_preview"
                                width={100}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-sm-12">
                      <div className="text-start">
                        <Link to="/excellence">
                          <button className="btn btn-secondary mr-2">
                            Back{" "}
                          </button>
                        </Link>
                        <button type="submit" className="btn btn-success">
                          {editData ? "Update" : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddExcellence;
