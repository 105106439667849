import React, { useState } from "react";
import { toast } from "react-toastify";
import axiosInstance from "../axiosInterceptor";

const Sitemap = () => {
    const [isGenerating, setIsGenerating] = useState(false);

    const handleClick = async () => {
        setIsGenerating(true);
        try {
            const response = await axiosInstance.get(`/sitemap`);
            if (response.data && response.data.message) {
                toast.success(response.data.message);
            } else {
                toast.warning("Sitemap generated, but no message received.");
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error(error.response.data.message);
            } else {
                toast.error("An unexpected error occurred.");
            }
        } finally {
            setIsGenerating(false);
        }
    };

    return (
        <div className="content-wrapper">
            <div className="page-header mt-5 mx-4">
                <h3 className="page-title">Sitemap</h3>
            </div>
            <div className="col-md-12 grid-margin stretch-card">
                <div className="col-md-8">
                    <div className="card">
                        <div className="card-body">
                            <div className="card-box height-100-p widget-style3 p-4">
                                <button
                                    onClick={handleClick}
                                    className="btn btn-inverse-info btn-fw"
                                    disabled={isGenerating}
                                >
                                    {isGenerating ? "Generating..." : "Generate Sitemap"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sitemap;
