import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInterceptor";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Loader from "../Layout/Loader";

const SocialMedia = () => {
  const [loading, setLoading] = useState(true);
  const [socialMedia, setSocialMedia] = useState([]);
  const navigator = useNavigate();

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`socialMedia/getSocialMedia`);
      const { data } = response?.data;
      setSocialMedia(data);
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this social media!",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        confirmDelete(id);
      }
    });
  };

  const confirmDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(
        `socialMedia/deleteSocialMedia/${id}`
      );
      if (response?.status === 200) {
        toast.success(response?.data?.message);
        fetchData();
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };
  const handleStatusToggle = async (id, modelName, isActive) => {
    try {
      const response = await axiosInstance.put(`admin/updateStatus/${id}`, {
        isActive: isActive,
        modelName: modelName,
      });
      if (response?.data?.isSuccess === true) {
        fetchData();
        toast.success(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "An error occurred");
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="page-header mt-5">
          <h3 className="page-title"> Social Media </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/add-social-media">
                  <button type="button" className="btn btn-inverse-info btn-fw">
                    <i className="icon-plus mx-2 mt-2"></i>
                    Add Social Media
                  </button>
                </Link>
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive border rounded p-1">
                  <table className="table text-start">
                    <thead>
                      <tr>
                        <th className="font-weight-bold">Name</th>
                        <th className="font-weight-bold">Url</th>
                        <th className="font-weight-bold">Icon</th>
                        <th className="font-weight-bold">IsActive</th>
                        <th className="font-weight-bold text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {socialMedia?.length > 0 ? (
                        socialMedia?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item?.name} </td>
                              <td>{item?.url} </td>
                              <td>{item?.icon}</td>
                              <td>
                                <div className="custom-control custom-switch">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name="isActive"
                                    id={`isActiveSwitch-${index}`}
                                    checked={item?.isActive}
                                    onChange={() =>
                                      handleStatusToggle(
                                        item?._id,
                                        "socialMedia",
                                        !item?.isActive
                                      )
                                    }
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`isActiveSwitch-${index}`}
                                  ></label>
                                </div>
                              </td>
                              <td className="text-center">
                                <div className="template-demo">
                                  <button
                                    type="button"
                                    className="btn btn-outline-primary btn-icon-text btn-sm w-40"
                                    onClick={() => {
                                      navigator("/add-social-media", {
                                        state: item,
                                      });
                                    }}
                                  >
                                    <i className="icon-pencil btn-icon-append mr-1"></i>{" "}
                                    Edit
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-outline-danger btn-icon-text btn-sm w-35"
                                    onClick={() => handleDelete(item?._id)}
                                  >
                                    <i className="icon-trash btn-icon-append"></i>{" "}
                                    Delete
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="5">
                            <h5>No data found.</h5>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialMedia;
