import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInterceptor";
import { toast } from "react-toastify";
import { ImageLink } from "../constants";

const AddOurCustomers = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const editData = location.state;

  const initialCustomerData = {
    position: "",
    images: "",
    isActive: false,
  };

  const [customers, setCustomers] = useState([initialCustomerData]);
  const [errors, setErrors] = useState([]);
  const [nextPosition, setNextPosition] = useState(1);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axiosInstance.get(
          "customer/getWithoutPaginationCustomer"
        );
        const customerData = response.data;

        if (customerData.length > 0) {
          const highestPosition = Math.max(
            ...customerData.map((customer) => customer.position)
          );
          setNextPosition(highestPosition + 1);
          if (editData?._id) {
            setCustomers([
              {
                ...editData,
                position: editData?.position || highestPosition + 1,
              },
            ]);
          } else {
            setCustomers([
              { ...initialCustomerData, position: highestPosition + 1 },
            ]);
          }
        } else {
          setNextPosition(1);
          setCustomers([{ ...initialCustomerData, position: 1 }]);
        }
      } catch (error) {
        toast.error("Failed to fetch customer data.");
      }
    };

    fetchCustomers();
  }, [editData]);

  const handleChange = (index, e) => {
    const { name, files, checked, value } = e.target;
    const updatedCustomers = [...customers];

    if (name === "images") {
      updatedCustomers[index][name] = files.length > 0 ? files[0] : null;
    } else if (name === "isActive") {
      updatedCustomers[index][name] = checked;
    } else {
      updatedCustomers[index][name] = value;
    }

    setCustomers(updatedCustomers);
  };

  const handleAddMore = () => {
    setNextPosition((prevPosition) => {
      const newCustomer = {
        ...initialCustomerData,
        position: prevPosition + 1,
      };
      setCustomers((prevCustomers) => [...prevCustomers, newCustomer]);
      setErrors((prevErrors) => [...prevErrors, {}]);
      return prevPosition + 1;
    });
  };

  const handleRemove = (index) => {
    const updatedCustomers = customers.filter((_, i) => i !== index);
    setCustomers(updatedCustomers);
    const updatedErrors = errors.filter((_, i) => i !== index);
    setErrors(updatedErrors);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasErrors = false;
    const validationErrors = [];

    customers.forEach((customer, index) => {
      const newErrors = {};
      if (!customer?.position) {
        newErrors.position = "Position is required";
        hasErrors = true;
      }
      if (!customer?.images) {
        newErrors.images = "Image is required";
        hasErrors = true;
      }
      validationErrors.push(newErrors);
    });

    setErrors(validationErrors);

    if (hasErrors) return;

    try {
      const formData = new FormData();

      // Append each customer object properly
      customers.forEach((customer) => {
        formData.append('customers[]', JSON.stringify({
          position: customer.position,
          isActive: customer.isActive,
        }));
        if (customer?.images instanceof File) {
          formData.append('images', customer.images); // Ensure this matches the multer field name
        }
      });

      const response = await axiosInstance.post(`customer/createCustomer`, formData);

      if (response.status === 200) {
        toast.success("Customers added successfully.");
        navigator("/our-customers");
      } else {
        toast.error("Some customers couldn't be added.");
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
        "Something went wrong. Please try again!"
      );
    }
  };




  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      const updatedCustomer = customers[0];

      formData.append("position", updatedCustomer?.position);
      if (updatedCustomer?.images instanceof File) {
        formData.append("image", updatedCustomer?.images);
      }
      formData.append("isActive", updatedCustomer?.isActive);

      const response = await axiosInstance.put(
        `customer/updateCustomer/${editData?._id}`,
        formData
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/our-customers");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="page-header mt-5 mx-4">
          <h3 className="page-title">
            {editData?._id ? "Edit Customer" : "Add Customer"}{" "}
          </h3>
        </div>
        <div className="col-md-12 grid-margin stretch-card">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <form
                  className="pt-3"
                  onSubmit={editData ? handleUpdate : handleSubmit}
                >
                  {customers.map((customer, index) => (
                    <div key={index} className="row mb-3">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label className="col-form-label">Position</label>
                          <input
                            type="number"
                            className="form-control form-control-sm"
                            name="position"
                            placeholder="Enter position"
                            value={customer?.position}
                            onChange={(e) => handleChange(index, e)}
                          />
                          {errors[index]?.position && (
                            <p className="text-danger">
                              {errors[index]?.position}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label className="col-form-label">Image</label>
                          <input
                            type="file"
                            className="form-control form-control-sm"
                            name="images"
                            onChange={(e) => handleChange(index, e)}
                          />
                          {errors[index]?.images && (
                            <p className="text-danger">
                              {errors[index]?.images}
                            </p>
                          )}
                          {customer?.images && (
                            <div className="pt-3">
                              {customer.images instanceof File ? (
                                <img
                                  src={URL.createObjectURL(customer?.images)}
                                  alt="image_preview"
                                  width={100}
                                />
                              ) : (
                                <img
                                  src={ImageLink(customer?.images)}
                                  alt="image_preview"
                                  width={100}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-2 pt-5">
                        <div className="form-group">
                          <div className="custom-control custom-switch">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="isActive"
                              id={`isActiveSwitch-${index}`}
                              checked={customer?.isActive}
                              onChange={(e) => handleChange(index, e)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`isActiveSwitch-${index}`}
                            >
                              {customer?.isActive ? "Active" : "Deactive"}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-2 pt-5">
                        {index > 0 && (
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={() => handleRemove(index)}
                          >
                            Remove
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                  {!editData && (
                    <>
                      <hr />
                      <div className="d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleAddMore}
                        >
                          Add More
                        </button>
                      </div>
                    </>
                  )}
                  <div className="mt-4">
                    <Link to="/our-customers">
                      <button className="btn btn-secondary mr-2">Back</button>
                    </Link>
                    <button type="submit" className="btn btn-success">
                      {editData ? "Update" : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddOurCustomers;
