// export const sortArrayByKey = (array, sortConfig) => {
//     const detectType = (value) => {
//       if (typeof value === 'number') return 'number';
//       if (!isNaN(Number(value)) && value !== '') return 'number';
//       return 'string';
//     };

//     const compareValues = (valueA, valueB, type, order) => {
//       if (type === 'number') {
//         valueA = Number(valueA);
//         valueB = Number(valueB);
//       } else {
//         valueA = String(valueA).toLowerCase();
//         valueB = String(valueB).toLowerCase();
//       }

//       if (valueA < valueB) return order === 'asc' ? -1 : 1;
//       if (valueA > valueB) return order === 'asc' ? 1 : -1;
//       return 0;
//     };

//     return [...array].sort((a, b) => {
//       for (const { key, order } of sortConfig) {
//         const type = detectType(a[key]);
//         const comparison = compareValues(a[key], b[key], type, order);
//         if (comparison !== 0) return comparison;
//       }
//       return 0;
//     });
//   };

export const sortArrayByKey = (array, key, order = 'asc') => {
    return [...array].sort((a, b) => {
        if (a[key] < b[key]) return order === 'asc' ? -1 : 1;
        if (a[key] > b[key]) return order === 'asc' ? 1 : -1;
        return 0;
    });
};