import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInterceptor";
import { toast } from "react-toastify";
import { ImageLink } from "../constants";

const AddPageContent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const editData = location.state;


    const [data, setData] = useState(() => {
        // Retrieve saved form data from local storage, if available
        const savedData = JSON.parse(localStorage.getItem("formData"));
        return savedData || {
            menu: "",
            image: [],
        };
    });

    const [menuData, setMenuData] = useState([]);
    const [contentImg, setContentImg] = useState([]);
    const [errors, setErrors] = useState({
        menu: "",
        image: "",
    });


    // Fetch menu data
    const fetchMenuData = async () => {
        try {
            const response = await axiosInstance.get("menu/getMenu");
            const menuOptions = response?.data?.data || [];
            setMenuData(menuOptions);
        } catch (error) {
            console.error("Error fetching menu data:", error);
        }
    };

    const fetchContentImg = async () => {
        try {
            const response = await axiosInstance.get("contentImg/getAllContentImg");
            const contentImgs = response?.data?.data || [];
            setContentImg(contentImgs);
        } catch (error) {
            console.error("Error fetching content images:", error);
        }
    };




    useEffect(() => {
        fetchMenuData();
        fetchContentImg();
    }, []);

    // Handle input changes
    const handleChange = (e) => {
        const { name, value, files } = e.target;

        if (name === "image") {
            const fileArray = Array.from(files || []);
            setData((prevData) => ({
                ...prevData,
                [name]: [...(prevData[name] || []), ...fileArray],
            }));

            // Clear image error when files are added
            if (fileArray.length > 0) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    image: "",
                }));
            }
        } else {
            setData((prevData) => ({
                ...prevData,
                [name]: value,
            }));

            // Clear error when user corrects input
            if (errors[name]) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [name]: "",
                }));
            }
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasErrors = false;

        // Validate inputs
        if (!data.menu) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                menu: "Menu is required",
            }));
            hasErrors = true;
        }

        if (!data.image || data.image.length === 0) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                image: "At least one image is required",
            }));
            hasErrors = true;
        }

        if (hasErrors) return;

        try {
            // Check if a record with the same menu already exists
            const existingRecord = contentImg.find(item => item.menu._id === data.menu);

            const formData = new FormData();
            formData.append("menu", data.menu);

            data.image.forEach((file) => {
                if (file instanceof File) {
                    formData.append("image", file);
                }
            });

            let response;
            if (existingRecord) {
                // If record exists, update the existing record
                response = await axiosInstance.put(
                    `contentImg/updateContentImg/${existingRecord._id}`,
                    formData
                );
            } else {
                // If no record exists, create a new one
                response = await axiosInstance.post(
                    `contentImg/createContentImg`,
                    formData
                );
            }

            if (response.status === 200) {
                toast.success(response.data.message);
                navigate("/add-page-content");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Something went wrong. Please try again!");
            console.error(error);
        }
    };

    // Handle image deletion
    const handleDeleteImage = async (index) => {
        if (editData?._id) {
            try {
                const response = await axiosInstance.delete(
                    `/product/${editData._id}/image/${index}`
                );

                if (response.status === 200) {
                    toast.success(response.data.message);

                    // Remove the image from state
                    const updatedImages = [...data.image];
                    updatedImages.splice(index, 1);
                    setData((prevData) => ({
                        ...prevData,
                        image: updatedImages,
                    }));
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                toast.error("Failed to delete image. Please try again.");
            }
        } else {
            const updatedImages = [...data.image];
            updatedImages.splice(index, 1);
            setData((prevData) => ({
                ...prevData,
                image: updatedImages,
            }));
        }
    };

    return (
        <div className="content-wrapper">
            <div className="page-header mt-5 mx-4">
                <h3 className="page-title">Add Content Images</h3>
            </div>
            <div className="col-md-12 grid-margin stretch-card">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <form className="pt-3" onSubmit={handleSubmit}>
                                <div className="row">
                                    {/* Menu Selection */}
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="menu" className="col-form-label">
                                                Page Name
                                            </label>
                                            <select
                                                className="form-control form-control-sm text-capitalize"
                                                id="menu"
                                                name="menu"
                                                onChange={handleChange}
                                                value={data?.menu}
                                            >
                                                <option value="" disabled>
                                                    Select Page Name
                                                </option>
                                                {menuData?.map((item) => (
                                                    <React.Fragment key={item?._id}>
                                                        {item?.submenus?.length > 0
                                                            ? item?.submenus?.map((subitem) => (
                                                                <option
                                                                    key={subitem?._id}
                                                                    value={subitem?._id}
                                                                    className="text-capitalize"
                                                                >
                                                                    {item?.menuName + " >> " + subitem?.menuName}
                                                                </option>
                                                            ))
                                                            : (
                                                                <option
                                                                    key={item?._id}
                                                                    value={item?._id}
                                                                    className="text-capitalize"
                                                                >
                                                                    {item?.menuName}
                                                                </option>
                                                            )}
                                                    </React.Fragment>
                                                ))}
                                            </select>
                                            {errors?.menu && (
                                                <p className="text-danger">{errors?.menu}</p>
                                            )}
                                        </div>
                                    </div>

                                    {/* Image Upload */}
                                    <div className="col-sm-6 pl-5">
                                        <div className="form-group row">
                                            <label htmlFor="image" className="col-form-label">
                                                Product Images
                                            </label>
                                            <input
                                                type="file"
                                                className="form-control form-control-sm"
                                                id="image"
                                                name="image"
                                                onChange={handleChange}
                                                multiple
                                            />
                                            {errors?.image && (
                                                <p className="text-danger">{errors?.image}</p>
                                            )}

                                            {/* Preview Uploaded Images */}
                                            {data?.image?.length > 0 && (
                                                <div className="mt-2 d-flex flex-wrap">
                                                    {data.image.map((file, index) => (
                                                        <div
                                                            className="border p-2 m-1 position-relative"
                                                            key={index}
                                                        >
                                                            {file instanceof File ? (
                                                                <img
                                                                    src={URL.createObjectURL(file)}
                                                                    alt={`productImage_preview_${index}`}
                                                                    width={100}
                                                                    className="mr-2 mb-2"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={ImageLink(file)}
                                                                    alt={`productImage_preview_${index}`}
                                                                    width={100}
                                                                    className="mr-2 mb-2"
                                                                />
                                                            )}
                                                            <button
                                                                className="position-absolute removeImg"
                                                                type="button"
                                                                onClick={() => handleDeleteImage(index)}
                                                            >
                                                                X
                                                            </button>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                {/* Buttons */}
                                <div className="text-start mt-4">
                                    <Link to="/add-page-content">
                                        <button className="btn btn-secondary mr-2">Back</button>
                                    </Link>
                                    <button type="submit" className="btn btn-success">
                                        {editData ? "Update" : "Submit"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddPageContent;