import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInterceptor";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Loader from "../Layout/Loader";
import { ImageLink } from "../constants";
import SortArrow from "./SortArrow";
import { sortArrayByKey } from "../js/sorting";
import {
  IoArrowDownCircleOutline,
  IoArrowUpCircleOutline,
} from "react-icons/io5";

const OurCustomers = () => {
  const [loading, setLoading] = useState(true);
  const [customerData, setCustomerData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    parseInt(sessionStorage.getItem("OurCustomers")) || 1
  );
  const [sortConfig, setSortConfig] = useState({
    key: "position",
    order: "desc",
  });

  const navigate = useNavigate();
  const perPage = 10;

  const fetchData = async (page = 1) => {
    try {
      const response = await axiosInstance.post(`customer/getAllCustomer`, {
        perPage: perPage,
        pageNo: page,
      });
      const { data, totalPages } = response.data;

      setCustomerData(data);
      setTotalPages(totalPages);
    } catch (error) {
      toast.error(
        error.response?.data.message ||
          "Something went wrong. Please try again!!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (key) => {
    const order =
      sortConfig.key === key && sortConfig.order === "asc" ? "desc" : "asc";
    setSortConfig({ key, order });
  };

  const sortedCustomers = sortArrayByKey(
    customerData,
    sortConfig.key,
    sortConfig.order
  );

  const updatePosition = async (id, newPosition) => {
    try {
      const itemsToUpdate = [{ id, position: newPosition }];
      const swappedItem = customerData.find(
        (item) => item.position === newPosition
      );

      if (swappedItem) {
        itemsToUpdate.push({
          id: swappedItem._id,
          position: customerData.find((item) => item._id === id).position,
        });
      }

      await axiosInstance.post("/admin/updatePosition", {
        modelName: "customer",
        items: itemsToUpdate,
      });

      // Fetch updated data after position update
      fetchData(currentPage);
      toast.success("Position updated successfully");
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    fetchData(currentPage);
    sessionStorage.setItem("OurCustomers", currentPage);
  }, [currentPage]);

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this customer!",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        confirmDelete(id);
      }
    });
  };

  const confirmDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(
        `customer/deleteCustomer/${id}`
      );
      toast.success(response.data.message);
      fetchData(currentPage);
    } catch (error) {
      toast.error(
        error.response?.data.message ||
          "Something went wrong. Please try again!!"
      );
    }
  };

  const handleStatusToggle = async (id, modelName, isActive) => {
    try {
      const response = await axiosInstance.put(`admin/updateStatus/${id}`, {
        isActive,
        modelName,
      });
      if (response.data.isSuccess) {
        fetchData(currentPage);
        toast.success(response.data?.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalPagesToShow = 5;

    let startPage = Math.max(1, currentPage - Math.floor(totalPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + totalPagesToShow - 1);

    if (endPage - startPage + 1 < totalPagesToShow) {
      startPage = Math.max(1, endPage - totalPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => handlePageClick(i)}>
            {i}
          </button>
        </li>
      );
    }

    if (startPage > 1) {
      pageNumbers.unshift(
        <li key="left-dots" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(
        <li key="right-dots" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }

    return pageNumbers;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="content-wrapper">
      <div className="page-header mt-5">
        <h3 className="page-title">Our Customers</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/add-our-customers">
                <button type="button" className="btn btn-inverse-info btn-fw">
                  <i className="icon-plus mx-2 mt-2"></i>Add Our Customers
                </button>
              </Link>
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive border rounded p-1">
                <table className="table text-center">
                  <thead>
                    <tr>
                      <th
                        onClick={() => handleSort("position")}
                        className="font-weight-bold sortDiv"
                      >
                        Position
                        <SortArrow
                          isSorted={sortConfig.key === "position"}
                          order={sortConfig.order}
                        />
                      </th>
                      <th className="font-weight-bold">Image</th>
                      <th className="font-weight-bold">Active</th>
                      <th className="font-weight-bold">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedCustomers.length === 0 ? (
                      <tr>
                        <td colSpan="4">
                          <h5>No data found.</h5>
                        </td>
                      </tr>
                    ) : (
                      sortedCustomers.map((item, index) => (
                        <tr key={item._id}>
                          <td className="sortingArrow">
                            <span>{item.position}</span>
                            <IoArrowUpCircleOutline
                              className="sorting"
                              onClick={() =>
                                updatePosition(item._id, item.position + 1)
                              }
                            />
                            <IoArrowDownCircleOutline
                              className="sorting"
                              onClick={() =>
                                updatePosition(item._id, item.position - 1)
                              }
                            />
                          </td>
                          <td>
                            <img
                              src={ImageLink(item.image)}
                              alt="image_img"
                              style={{ borderRadius: "0px", width: "auto" }}
                            />
                          </td>
                          <td>
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`isActiveSwitch-${index}`}
                                checked={item.isActive}
                                onChange={() =>
                                  handleStatusToggle(
                                    item._id,
                                    "customer",
                                    !item.isActive
                                  )
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`isActiveSwitch-${index}`}
                              ></label>
                            </div>
                          </td>
                          <td>
                            <div className="template-demo">
                              <button
                                type="button"
                                className="btn btn-outline-primary btn-icon-text btn-sm w-40"
                                onClick={() =>
                                  navigate("/add-our-customers", {
                                    state: item,
                                  })
                                }
                              >
                                <i className="icon-pencil btn-icon-append mx-2"></i>
                                Edit
                              </button>
                              <button
                                type="button"
                                className="btn btn-outline-danger btn-icon-text btn-sm w-35"
                                onClick={() => handleDelete(item._id)}
                              >
                                <i className="icon-trash btn-icon-append"></i>
                                Delete
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
              <div className="d-flex mt-4 flex-wrap">
                <p className="text-muted">
                  Showing {(currentPage - 1) * perPage + 1} to{" "}
                  {Math.min(currentPage * perPage, customerData.length)} of{" "}
                  {totalPages * perPage} entries
                </p>
                <nav className="ml-auto">
                  <ul className="pagination separated pagination-info">
                    <li
                      className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageClick(currentPage - 1)}
                      >
                        <i className="icon-arrow-left" />
                      </button>
                    </li>
                    {renderPageNumbers()}
                    <li
                      className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageClick(currentPage + 1)}
                      >
                        <i className="icon-arrow-right" />
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurCustomers;
