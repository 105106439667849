import React from "react";
// import { Line } from "react-chartjs-2";
// import "chart.js/auto";

const Dashboard = () => {
  const chartData = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Visits",
        data: [65, 59, 80, 94, 56, 55, 70, 62, 110, 78, 92, 150],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.6)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  };

  return (
    <>
      <div className="content-wrapper">
        {/* <section className="analytics">
          <div className="analytics-card">
            <h3>Total Visits</h3>
            <p>1,200</p>
          </div>
          <div className="analytics-card">
            <h3>Active Users</h3>
            <p>300</p>
          </div>
          <div className="analytics-card">
            <h3>New Signups</h3>
            <p>80</p>
          </div>
          <div className="analytics-card">
            <h3>Sales</h3>
            <p>$5,400</p>
          </div>
        </section> */}

        {/* Chart Section */}
        {/* <section className="chart-section">
          <h2>Visits Over Time</h2>
          <Line data={chartData} />
        </section> */}
      </div>
    </>
  );
};

export default Dashboard;
