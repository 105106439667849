import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInterceptor";
import { toast } from "react-toastify";
import { ImageLink } from "../constants";

const AddManagement = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const editData = location.state;
  const [data, setData] = useState({
    position: editData?.position || "",
    name: editData?.name || "",
    job: editData?.job || "",
    location: editData?.location || "",
    profession: editData?.profession || "",
    image: editData?.image || "",
  });

  const [errors, setErrors] = useState({
    position: "",
    name: "",
    job: "",
    location: "",
    profession: "",
    image: "",
  });


  useEffect(() => {
    if (!editData) {
      fetchMaxPosition();
    }
  }, []);

  const fetchMaxPosition = async () => {
    try {
      const response = await axiosInstance.get('/Management/getWithoutPaginationManagement');
      if (response.status === 200) {
        const managements = response.data;
        if (Array.isArray(managements)) {
          const maxPosition = Math.max(...managements.map(management => management.position), 0);

          setData(prevData => ({
            ...prevData,
            position: maxPosition + 1
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching banners:", error);
      toast.error("Failed to fetch banner data. Please try again.");
    }
  };


  const handleChange = (e) => {
    const { name, files, checked, value } = e.target;
    if (name === "image") {
      setData({ ...data, [name]: files.length > 0 ? files[0] : null });
    } else if (name === "isActive") {
      setData({ ...data, [name]: checked });
    } else {
      setData({ ...data, [name]: value });
    }
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasErrors = false;

    if (!data?.position) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        position: "Position is required",
      }));
      hasErrors = true;
    }
    if (!data?.name.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "Name is required",
      }));
      hasErrors = true;
    }
    if (!data?.job.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        job: "Job is required",
      }));
      hasErrors = true;
    }
    if (!data?.location) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        location: "Location is required",
      }));
      hasErrors = true;
    }
    if (!data.profession) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        profession: "Profession is required",
      }))
    }
    if (!data?.image) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: "Desktop img is required",
      }));
      hasErrors = true;
    }
    if (hasErrors) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("position", data?.position);
      formData.append("name", data?.name);
      formData.append("job", data?.job);
      formData.append("location", data?.location);
      formData.append("profession", data?.profession);
      if (data?.image instanceof File) {
        formData.append("image", data?.image);
      }

      const response = await axiosInstance.post(
        `/management/createManagement`,
        formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/management");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong. Please try again!!");
      }
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("position", data?.position);
      formData.append("name", data?.name);
      formData.append("job", data?.job);
      formData.append("location", data?.location);
      formData.append("profession", data?.profession);
      if (data?.image instanceof File) {
        formData.append("image", data?.image);
      }

      const response = await axiosInstance.put(
        `/management/updateManagement/${editData?._id}`,
        formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/management");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong. Please try again!!");
      }
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="page-header mt-5 mx-4">
          <h3 className="page-title">
            {editData?._id ? "Edit Management" : "Add Management"}
          </h3>
        </div>
        <div className="col-md-12 grid-margin stretch-card">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <form
                  className="pt-3"
                  onSubmit={editData?._id ? handleUpdate : handleSubmit}
                >
                  <div className="row">
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputUsername2"
                            className="col-form-label"
                          >
                            Position
                          </label>
                          <input
                            type="number"
                            className="form-control form-control-sm"
                            id="position"
                            name="position"
                            placeholder="Enter slider sort order"
                            value={data.position}
                            onChange={handleChange}
                          />
                          {errors.position && <p className="text-danger">{errors.position}</p>}
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputUsername2"
                            className="col-form-label"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="name"
                            name="name"
                            placeholder="Enter a name"
                            value={data?.name}
                            onChange={handleChange}
                          />
                          {errors?.name && (
                            <p className="text-danger">{errors?.name}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputUsername2"
                            className="col-form-label"
                          >
                            job
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="job"
                            name="job"
                            placeholder="Enter a job"
                            value={data?.job}
                            onChange={handleChange}
                          />
                          {errors?.job && (
                            <p className="text-danger">{errors?.job}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputUsername2"
                            className="col-form-label"
                          >
                            Location
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="location"
                            name="location"
                            placeholder="Enter a location"
                            value={data?.location}
                            onChange={handleChange}
                          />
                          {errors?.location && (
                            <p className="text-danger">{errors?.location}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputUsername2"
                            className="col-form-label"
                          >
                            Profession
                          </label>
                          <textarea
                            type="text"
                            className="form-control form-control-sm"
                            rows="5"
                            id="profession"
                            name="profession"
                            placeholder="Enter a Profession"
                            value={data?.profession}
                            onChange={handleChange}
                          />
                          {errors?.profession && (
                            <p className="text-danger">{errors?.profession}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label
                            htmlFor="exampleInputUsername2"
                            className="col-form-label"
                          >
                            Image
                          </label>
                          <input
                            type="file"
                            className="form-control form-control-sm"
                            id="image"
                            name="image"
                            onChange={handleChange}
                          />
                          {errors?.image && (
                            <p className="text-danger">{errors?.image}</p>
                          )}
                          <div>
                            {data?.image && (
                              <div className="mt-2">
                                {data.image instanceof File ? (
                                  <img
                                    src={URL.createObjectURL(data?.image)}
                                    alt="image_preview"
                                    height={100}
                                  />
                                ) : (
                                  <img
                                    src={ImageLink(data?.image)}
                                    alt="image_preview"
                                    height={100}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="row mt-4">
                    <div className="col-sm-12">
                      <div className="text-start">
                        <Link to="/management">
                          <button className="btn btn-secondary mr-2">
                            Back{" "}
                          </button>
                        </Link>
                        <button type="submit" className="btn btn-success">
                          {editData?._id ? "Update" : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddManagement;
