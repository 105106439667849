import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import Footer from "./Layout/Footer";
import Header from "./Layout/Header";
import Sidebar from "./Layout/Sidebar";
import Login from "./authentication/Login";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
// import Forgotpassword from "./authentication/Forgotpassword";
import AddCounter from "./masterpages/AddCounter";
import Counter from "./masterpages/Counter";
import Menu from "./masterpages/Menu";
import AddMenu from "./masterpages/AddMenu";
import Setting from "./setting/Setting";
// import ResetPassword from "./authentication/ResetPassword";
import ChangePasssword from "./authentication/ChangePasssword";
import EmailSetting from "./setting/EmailSetting";

import Slider from "./masterpages/Slider";
import AddSlider from "./masterpages/AddSlider";

import Pagewisebanner from "./masterpages/Pagewisebanner";
import AddPagewisebanner from "./masterpages/AddPagewisebanner";

import Enquirys from "./masterpages/Enquirys";
import Enquirysdetails from "./masterpages/Enquirysdetails";

import Profile from "./masterpages/Profile";
import SocialMedia from "./masterpages/SocialMedia";
import AddSocialMedia from "./masterpages/AddSocialMedia";
import EmailTemplate from "./setting/EmailTemplate";
import Sitemap from "./masterpages/Sitemap";
import Submenu from "./masterpages/Submenu";
import AddSubmenu from "./masterpages/AddSubmenu";
import Scroll from "./Layout/Scroll";
import Cms from "./masterpages/Cms";
import AddCms from "./masterpages/AddCms";
import Milestone from "./masterpages/Milestone";
import AddMilestone from "./masterpages/AddMilestone";
import About from "./masterpages/About";
import AddAbout from "./masterpages/AddAbout";
import Companies from "./masterpages/Companies";
import AddCompanies from "./masterpages/AddCompanies";
import Management from "./masterpages/Management";
import AddManagement from "./masterpages/AddManagement";
import Products from "./masterpages/Products";
import AddProducts from "./masterpages/AddProducts";
import OurCustomers from "./masterpages/OurCustomers";
import AddOurCustomers from "./masterpages/AddOurCustomers";
import ReportCategory from "./masterpages/ReportCategory";
import AddReportCategory from "./masterpages/AddReportCategory";
import Subcategory from "./masterpages/SubCategory";
import AddSubCategory from "./masterpages/AddSubCategory";
import CategoryFile from "./masterpages/CategoryFile";
import AddSubCategoryFile from "./masterpages/AddSubCategoryFile";
import Excellence from "./masterpages/Excellence";
import AddExcellence from "./masterpages/AddExcellence";
import PageContent from "./masterpages/PageContent";
import AddPageContent from "./masterpages/AddPageContent";
import PageContentImg from "./masterpages/PageContentImg";
import HomeSetting from "./masterpages/HomeSettings";
import Investors from "./masterpages/Investors";
import AddInvestors from "./masterpages/AddInvestors";

function RequireAuth({ children }) {
  let accessToken = localStorage.getItem("eagleAdminToken");
  if (!accessToken) {
    return <Navigate to="/" />;
  }
  return children;
}

function App() {
  let accessToken = localStorage.getItem("eagleAdminToken");
  return (
    <Router>
      <Routes>
        {!accessToken && <Route path="/" element={<Login />} />}
        {/* <Route path="/forgot-password" element={<Forgotpassword />} /> */}
        {/* <Route path="/reset-password" element={<ResetPassword />} /> */}
        <Route
          path="*"
          element={
            <RequireAuth>
              <Scroll />
              <Header />
              <div className="container-scroller">
                <div className="container-fluid page-body-wrapper">
                  <Sidebar />
                  <div className="main-panel">
                    <Routes>
                      <Route path="/dashboard" element={<Dashboard />} />

                      <Route path="/menu" element={<Menu />} />
                      <Route path="/add-menu" element={<AddMenu />} />
                      <Route path="/menu/:id" element={<Submenu />} />
                      <Route
                        path="/add-sub-menu/:parentId"
                        element={<AddSubmenu />}
                      />
                      <Route path="/settings" element={<Setting />} />
                      <Route
                        path="/change-password"
                        element={<ChangePasssword />}
                      />
                      <Route
                        path="/email-configuration"
                        element={<EmailSetting />}
                      />

                      <Route path="/slider" element={<Slider />} />
                      <Route path="/add-slider" element={<AddSlider />} />

                      <Route path="/home-settings" element={<HomeSetting />} />

                      <Route path="/banner" element={<Pagewisebanner />} />
                      <Route
                        path="/add-banner"
                        element={<AddPagewisebanner />}
                      />

                      <Route path="/enquiries" element={<Enquirys />} />
                      <Route
                        path="/enquiries-details"
                        element={<Enquirysdetails />}
                      />

                      <Route path="/profile" element={<Profile />} />
                      <Route path="/social-media" element={<SocialMedia />} />
                      <Route
                        path="/add-social-media"
                        element={<AddSocialMedia />}
                      />
                      <Route
                        path="/email-template"
                        element={<EmailTemplate />}
                      />
                      <Route path="/sitemap" element={<Sitemap />} />
                      <Route path="/cms" element={<Cms />} />
                      <Route path="/add-cms" element={<AddCms />} />

                      <Route path="/about" element={<About />} />
                      <Route path="/add-about" element={<AddAbout />} />

                      <Route path="/milestone" element={<Milestone />} />
                      <Route path="/add-milestone" element={<AddMilestone />} />

                      <Route path="/counter" element={<Counter />} />
                      <Route path="/add-counter" element={<AddCounter />} />

                      <Route path="/excellence" element={<Excellence />} />
                      <Route path="/add-excellence" element={<AddExcellence />} />

                      <Route path="/page-content" element={<PageContent />} />
                      <Route path="/add-page-content" element={<AddPageContent />} />
                      <Route path="/page-content-img" element={<PageContentImg />} />


                      <Route path="/our-customers" element={<OurCustomers />} />
                      <Route
                        path="/add-our-customers"
                        element={<AddOurCustomers />}
                      />

                      <Route path="/companies" element={<Companies />} />
                      <Route path="/add-companies" element={<AddCompanies />} />

                      <Route path="/investors" element={<Investors />} />
                      <Route path="/add-investor" element={<AddInvestors />} />

                      <Route path="/management" element={<Management />} />
                      <Route
                        path="/add-management"
                        element={<AddManagement />}
                      />

                      <Route path="/product" element={<Products />} />
                      <Route path="/add-product" element={<AddProducts />} />

                      <Route path="/report-category" element={<ReportCategory />} />
                      <Route path="/add-report-category" element={<AddReportCategory />} />

                      <Route path="/report-category/:id" element={<Subcategory />} />
                      <Route path="/add-sub-category/:parentId" element={<AddSubCategory />} />

                      <Route path="/category-file/:id" element={<CategoryFile />} />
                      <Route path="/add-category-file/:parentId" element={<AddSubCategoryFile />} />
                    </Routes>
                    <Footer />
                  </div>
                </div>
              </div>
            </RequireAuth>
          }
        />
      </Routes>
      <ToastContainer autoClose={1000} />
    </Router>
  );
}

export default App;
