import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInterceptor";
import { toast } from "react-toastify";
import Loader from "../Layout/Loader";
import Swal from "sweetalert2";
import SortArrow from './SortArrow';
import { sortArrayByKey } from '../js/sorting'; // Adjust the import path as needed
import { IoArrowDownCircleOutline, IoArrowUpCircleOutline } from "react-icons/io5";

const Milestone = () => {
  const [loading, setLoading] = useState(true);
  const [milestoneData, setMilestoneData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    parseInt(sessionStorage.getItem("milestoneCurrentPage")) || 1
  );
  const [sortConfig, setSortConfig] = useState({
    key: "position",
    order: "desc",
  });
  const navigator = useNavigate();
  const perPage = 10;

  const fetchData = async (page = 1) => {
    try {
      const response = await axiosInstance.post(`milestone/getAllMilestone`, {
        perPage: perPage,
        pageNo: page,
      });
      const { data, totalPages } = response.data;
      setMilestoneData(data);
      setTotalPages(totalPages);
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
        "Something went wrong. Please try again!!"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
    sessionStorage.setItem("milestoneCurrentPage", currentPage);
  }, [currentPage]);

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const updatePosition = async (id, newPosition) => {
    try {
      const itemsToUpdate = [{ id, position: newPosition }];
      const swappedItem = milestoneData.find(item => item.position === newPosition);

      if (swappedItem) {
        itemsToUpdate.push({ id: swappedItem._id, position: milestoneData.find(item => item._id === id).position });
      }

      await axiosInstance.post('/admin/updatePosition', {
        modelName: 'milestone',
        items: itemsToUpdate,
      });

      // Fetch updated data after position update
      fetchData(currentPage);
      toast.success("Position updated successfully");
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };


  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this milestone!",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        confirmDelete(id);
      }
    });
  };

  const confirmDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(
        `milestone/deleteMilestone/${id}`
      );
      toast.success(response.data.message);
      fetchData(currentPage);
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
        "Something went wrong. Please try again!!"
      );
    }
  };

  const handleSort = (key) => {
    const order =
      sortConfig.key === key && sortConfig.order === "asc" ? "desc" : "asc";
    setSortConfig({ key, order });
  };

  const sortedMilestones = sortArrayByKey(
    milestoneData,
    sortConfig.key,
    sortConfig.order
  );

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalPagesToShow = 5;
    let startPage = Math.max(1, currentPage - Math.floor(totalPagesToShow / 2));
    let endPage = Math.min(totalPages, startPage + totalPagesToShow - 1);

    if (endPage - startPage + 1 < totalPagesToShow) {
      startPage = Math.max(1, endPage - totalPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <button className="page-link" onClick={() => handlePageClick(i)}>
            {i}
          </button>
        </li>
      );
    }

    if (startPage > 1) {
      pageNumbers.unshift(
        <li key="left-dots" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }

    if (endPage < totalPages) {
      pageNumbers.push(
        <li key="right-dots" className="page-item disabled">
          <span className="page-link">...</span>
        </li>
      );
    }

    return pageNumbers;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="content-wrapper">
      <div className="page-header mt-5">
        <h3 className="page-title">Milestone</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/add-milestone">
                <button type="button" className="btn btn-inverse-info btn-fw">
                  <i className="icon-plus mx-2 mt-2"></i>Add Milestone
                </button>
              </Link>
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive border rounded p-1">
                <table className="table text-center">
                  <thead>
                    <tr>
                      <th onClick={() => handleSort('position')} className="font-weight-bold sortDiv">
                        Position
                        <SortArrow isSorted={sortConfig.key === 'position'} order={sortConfig.order} />
                      </th>
                      <th onClick={() => handleSort('year')} className="font-weight-bold sortDiv">
                        Year
                        <SortArrow isSorted={sortConfig.key === 'year'} order={sortConfig.order} />
                      </th>
                      <th className="font-weight-bold">Description</th>
                      <th className="font-weight-bold">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedMilestones.length === 0 ? (
                      <tr>
                        <td colSpan="4">
                          <h5>No data found.</h5>
                        </td>
                      </tr>
                    ) : (
                      sortedMilestones.map((item) => (
                        <tr key={item._id}>
                          <td className="sortingArrow">
                            <span>{item.position}</span>
                            <IoArrowUpCircleOutline
                              className="sorting"
                              onClick={() => updatePosition(item._id, item.position + 1)}
                            />
                            <IoArrowDownCircleOutline
                              className="sorting"
                              onClick={() => updatePosition(item._id, item.position - 1)}
                            />

                          </td>
                          <td>{item.year}</td>
                          <td>{item.description.length > 50 ? `${item.description.substring(0, 50)}...` : item.description}</td>
                          <td>
                            <div className="template-demo">
                              <button
                                type="button"
                                className="btn btn-outline-primary btn-icon-text btn-sm w-40"
                                onClick={() => navigator("/add-milestone", { state: item })}
                              >
                                <i className="icon-pencil btn-icon-append mx-2"></i>
                                Edit
                              </button>
                              <button
                                type="button"
                                className="btn btn-outline-danger btn-icon-text btn-sm w-35"
                                onClick={() => handleDelete(item._id)}
                              >
                                <i className="icon-trash btn-icon-append"></i>
                                Delete
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
              <div className="d-flex mt-4 flex-wrap">
                <p className="text-muted">
                  Showing {(currentPage - 1) * perPage + 1} to {Math.min(currentPage * perPage, milestoneData.length)} of {totalPages * perPage} entries
                </p>
                <nav className="ml-auto">
                  <ul className="pagination separated pagination-info">
                    <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                      <button className="page-link" onClick={() => handlePageClick(currentPage - 1)}>
                        <i className="icon-arrow-left" />
                      </button>
                    </li>
                    {renderPageNumbers()}
                    <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
                      <button className="page-link" onClick={() => handlePageClick(currentPage + 1)}>
                        <i className="icon-arrow-right" />
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Milestone;
