import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInterceptor";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Loader from "../Layout/Loader";

const Cms = () => {
  const [loading, setLoading] = useState(true);
  const [pageData, setPageData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    parseInt(sessionStorage.getItem("Cms")) || 1
  );
  const navigator = useNavigate();
  const perPage = 10;

  const fetchData = async (page = 1) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(`/cms/getAllCms`, {
        perPage: perPage,
        pageNo: page,
      });
      const { data, totalPages } = response.data;
      setPageData(data);
      setTotalPages(totalPages);
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage);
    sessionStorage.setItem("Cms", currentPage);
  }, [currentPage]);

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this cms!",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        confirmDelete(id);
      }
    });
  };

  const confirmDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(`cms/deleteCms/${id}`);
      if (response.status === 200) {
        toast.success(response.data.message);
        setPageData(pageData.filter((item) => item._id !== id));
        await fetchData(currentPage);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  const handleStatusToggle = async (id, modelName, isActive) => {
    try {
      const response = await axiosInstance.put(`admin/updateStatus/${id}`, {
        isActive: isActive,
        modelName: modelName,
      });
      if (response.data.isSuccess === true) {
        const updatedData = pageData.map((item) =>
          item._id === id ? { ...item, isActive: isActive } : item
        );
        setPageData(updatedData);
        toast.success(response.data?.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }
  return (
    <>
      <div className="content-wrapper">
        <div className="page-header mt-5">
          <h3 className="page-title">Cms </h3>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/add-cms">
                  <button type="button" className="btn btn-inverse-info btn-fw">
                    <i className="icon-plus mx-2 mt-2"></i>
                    Add Cms
                  </button>
                </Link>
              </li>
            </ol>
          </nav>
        </div>
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive border rounded p-1">
                  <table className="table text-center">
                    <thead>
                      <tr>
                        <th className="font-wight-bold">#</th>
                        <th className="font-weight-bold">Title</th>
                        <th className="font-weight-bold">Url</th>
                        <th className="font-weight-bold">Description</th>
                        <th className="font-weight-bold">Active</th>
                        <th className="font-weight-bold text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pageData.length > 0 ? (
                        pageData.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.title}</td>
                              <td>{item.url}</td>
                              <td
                                dangerouslySetInnerHTML={{
                                  __html:
                                    item?.description?.length > 50
                                      ? `${item.description.substring(0, 50)}...`
                                      : item.description,
                                }}
                              />
                              <td>
                                <div className="custom-control custom-switch">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    name="isActive"
                                    id={`isActiveSwitch-${index}`}
                                    checked={item.isActive}
                                    onChange={() =>
                                      handleStatusToggle(
                                        item._id,
                                        "cms",
                                        !item.isActive
                                      )
                                    }
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor={`isActiveSwitch-${index}`}
                                  ></label>
                                </div>
                              </td>
                              <td className="text-center">
                                <div className="template-demo">
                                  <button
                                    type="button"
                                    className="btn btn-outline-primary btn-icon-text btn-sm"
                                    onClick={() => {
                                      navigator("/add-cms", { state: item });
                                    }}
                                  >
                                    <i className="icon-pencil btn-icon-append mr-1"></i>{" "}
                                    Edit
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-outline-danger btn-icon-text btn-sm"
                                    onClick={() => handleDelete(item._id)}
                                  >
                                    <i className="icon-trash mr-1"></i>Delete{" "}
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="8">
                            <h5>No data found.</h5>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex mt-4 flex-wrap">
                  <p className="text-muted">
                    Showing {(currentPage - 1) * perPage + 1} to{" "}
                    {Math.min(currentPage * perPage, pageData.length)} of{" "}
                    {totalPages * perPage} entries
                  </p>
                  <nav className="ml-auto">
                    <ul className="pagination separated pagination-info">
                      <li
                        className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageClick(currentPage - 1)}
                        >
                          <i className="icon-arrow-left" />
                        </button>
                      </li>
                      {Array.from({ length: totalPages }, (_, i) => (
                        <li
                          key={i}
                          className={`page-item ${currentPage === i + 1 ? "active" : ""}`}
                        >
                          <button
                            className="page-link"
                            onClick={() => handlePageClick(i + 1)}
                          >
                            {i + 1}
                          </button>
                        </li>
                      ))}
                      <li
                        className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageClick(currentPage + 1)}
                        >
                          <i className="icon-arrow-right" />
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cms;
