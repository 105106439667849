import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInterceptor';
import { toast } from 'react-toastify';
import CodeEditor from './CodeEditor';
import { ImageLink } from '../constants';

const AddPageContent = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const editData = location.state;


    const [data, setData] = useState({
        description: editData?.description || '',
        menu: editData?.menu || '',
        showInHome: editData?.showInHome || false,

    });

    const [menuData, setMenuData] = useState([])
    const [contentImg, setContentImg] = useState([]);

    useEffect(() => {
        // Fetch saved data from localStorage
        const savedData = localStorage.getItem('formData');
        if (savedData) {
            const parsedData = JSON.parse(savedData);

            // Extract `_id` and update `editData`
            if (parsedData._id) {
                location.state = { ...location.state, _id: parsedData._id }; // Update editData with _id
            }

            // Remove `_id` from saved data and set the rest to state
            const { _id, ...remainingData } = parsedData;
            setData(remainingData);
        }

        // Fetch menu data and content images
        fetchMenuData();
        fetchContentImg();
    }, []); // Run once on component mount


    const handleSaveToLocalStorage = () => {

        let sData = data;
        if (editData?._id) {
            sData = {
                ...data,
                _id: editData?._id
            }
            localStorage.setItem('formData', JSON.stringify(sData));
        } else {
            sData = {
                ...data,
                _id: null
            }
            localStorage.setItem('formData', JSON.stringify(sData));
        }

    };

    const clearLocalStorage = () => {
        localStorage.removeItem('formData');
    };


    const fetchMenuData = async () => {
        try {
            const response = await axiosInstance.get('menu/getMenu');
            const data = response?.data?.data;
            setMenuData(data);

        } catch (error) {
            console.error('Error fetching menu data:', error);
        }
    }

    const fetchContentImg = async () => {
        try {
            const response = await axiosInstance.get("contentImg/getAllContentImg");
            const contentImgs = response?.data?.data || [];
            setContentImg(contentImgs);
        } catch (error) {
            console.error("Error fetching content images:", error);
        }
    };


    const [errors, setErrors] = useState({
        menu: '',
        description: ''
    });

    const handleChange = (e) => {
        const { name, value, checked } = e.target;
        if (name === 'showInHome') {
            setData(prevData => ({
                ...prevData,
                [name]: checked
            }));
        } else {
            setData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };

    const validateForm = () => {
        let formErrors = {};

        if (!data.description.trim()) {
            formErrors.description = 'Description is required';
        }
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };



    useEffect(() => {
        if (!editData) {
            fetchMaxPosition();
        }
    }, []);

    const fetchMaxPosition = async () => {
        try {
            const response = await axiosInstance.get('/content/getWithoutPaginationContent');
            if (response.status === 200) {
                const banners = response.data;
                if (Array.isArray(banners)) {
                    const maxPosition = Math.max(...banners.map(banner => banner.position), 0);

                    setData(prevData => ({
                        ...prevData,
                        position: maxPosition + 1
                    }));
                }
            }
        } catch (error) {
            console.error("Error fetching banners:", error);
            toast.error("Failed to fetch banner data. Please try again.");
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        clearLocalStorage();
        if (!validateForm()) return;

        try {
            // const formData = new FormData();
            // formData.append("menu", data.menu);
            // formData.append("description", data.description);
            // formData.append("showInHome", data.showInHome);


            const response = await axiosInstance.post(`content/createContent`, data);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigate("/page-content");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!');
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        clearLocalStorage();
        if (!validateForm()) return;

        try {
            const formData = new FormData();
            formData.append("menu", data.menu);
            formData.append("description", data.description);
            formData.append("showInHome", data.showInHome);

            // Append new images (if any) to the form data
            if (data.newImages) {
                data.newImages.forEach((file) => {
                    formData.append("newImages", file); // `newImages` is a key to differentiate
                });
            }

            const response = await axiosInstance.put(`content/updateContent/${editData?._id}`, formData);

            if (response.status === 200) {
                toast.success(response.data.message);
                navigate("/page-content");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Something went wrong. Please try again!");
        }
    };


    const copyImagePath = async (path) => {
        navigator.clipboard.writeText(path)
            .then(() => {
                toast.success('Image path copied to clipboard');
            })
            .catch(err => {
                toast.error('Failed to copy image path');
                console.error('Copy failed', err);
            });
    };
    const handleDeleteImage = async (id, index) => {
        try {
            const response = await axiosInstance.delete(
                `/contentImg/${id}/image/${index}`
            );

            if (response.status === 200) {
                toast.success(response.data.message);

                // Update `contentImg` state to reflect the removed image
                setContentImg(prevContentImg =>
                    prevContentImg.map(item =>
                        item._id === id
                            ? {
                                ...item,
                                image: item.image.filter((_, imgIndex) => imgIndex !== index),
                            }
                            : item
                    )
                );
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("Error deleting image:", error);

            if (error.response) {
                toast.error(error.response.data.message || "Failed to delete image");
            } else {
                toast.error("Failed to delete image. Please try again.");
            }
        }
    };


    return (
        <div className="content-wrapper">
            <div className="page-header mt-5 mx-4">
                <h3 className="page-title">{editData ? 'Edit Page Content' : 'Add Page Content'}</h3>
            </div>
            <div className="col-md-12 grid-margin stretch-card">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <form className="pt-3" onSubmit={editData ? handleUpdate : handleSubmit}>
                                <div className="row">

                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="menu" className="col-form-label">
                                                Page Name
                                            </label>

                                            <select
                                                className="form-control form-control-sm text-capitalize"
                                                id="menu"
                                                name="menu"
                                                onChange={handleChange}
                                                value={data?.menu}
                                            >
                                                <option value="" disabled>Select Page Name</option>
                                                {menuData?.length > 0 && menuData?.map((item) => (
                                                    <React.Fragment key={item?._id}>
                                                        {item?.submenus && item?.submenus?.length > 0 ? (
                                                            item?.submenus?.map((subitem) => (
                                                                <option key={subitem?._id} value={subitem?._id} className='text-capitalize'>
                                                                    {item?.menuName + ' >> ' + subitem?.menuName}
                                                                </option>
                                                            ))
                                                        ) : (
                                                            <option key={item?._id} value={item?._id} className='text-capitalize'>
                                                                {item?.menuName}
                                                            </option>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </select>

                                        </div>
                                    </div>
                                    <div className="col-sm-6 mt-5 pl-5">
                                        <div className="form-group row">
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    name="showInHome"
                                                    id="showInHomeSwitch"
                                                    checked={data?.showInHome}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                                <label
                                                    className="custom-control-label "
                                                    for="showInHomeSwitch"
                                                >
                                                    {data?.showInHome === true ? "Show In Home" : "Don't Show In Home"}
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                    {/* Description */}
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="description" className="col-form-label">Description</label>
                                            <CodeEditor
                                                value={data.description}
                                                style={{ height: '400px' }}
                                                onChange={(value) => handleChange({ target: { name: 'description', value } })}
                                            />
                                            {errors.description && !data.description.trim() && (
                                                <div className="text-danger">{errors.description}</div>
                                            )}
                                        </div>
                                    </div>


                                    {/* Image */}
                                    <div className="col-sm-12 mt-4">
                                        <div className="form-group">
                                            <label className="col-form-label">All Images</label>
                                            <div className="row">
                                                {data.menu ? (
                                                    contentImg.filter(item => item.menu?._id === data.menu).length > 0 ? (
                                                        contentImg
                                                            .filter(item => item.menu?._id === data.menu)
                                                            .map((item, mainIndex) => (
                                                                <div key={item._id} >
                                                                    {item.image && item.image.length > 0 ? (
                                                                        <div className="d-flex flex-wrap">
                                                                            {item.image.map((img, index) => (
                                                                                <div
                                                                                    key={index}
                                                                                    className="border p-2 m-1 position-relative"

                                                                                >
                                                                                    {/* Close Button */}
                                                                                    <button
                                                                                        className="position-absolute removeImg"

                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            handleDeleteImage(item?._id, mainIndex)
                                                                                        }}
                                                                                    >
                                                                                        &times;
                                                                                    </button>

                                                                                    {/* Image */}
                                                                                    <img
                                                                                        src={ImageLink(img)}
                                                                                        alt={item.menu?.menuName || 'Content Image'}
                                                                                        width={100}

                                                                                        className="mr-2 mb-2"
                                                                                    />

                                                                                    {/* Copy Path Button */}
                                                                                    <div
                                                                                        className="text-center mt-2"

                                                                                    >
                                                                                        <button
                                                                                            className="btn btn-primary btn-sm"
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                copyImagePath(ImageLink(img))
                                                                                            }}
                                                                                        >
                                                                                            Copy Path
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    ) : (
                                                                        <p>No images uploaded for this page</p>
                                                                    )}
                                                                </div>
                                                            ))
                                                    ) : (
                                                        <p>No images uploaded for this page</p>
                                                    )
                                                ) : (
                                                    <p>Please select a page name first</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    {/* Upload Image */}
                                    <div className="col-sm-12 mt-4">
                                        <div className="form-group">
                                            <label className="col-form-label">Upload Image Here</label>
                                            <Link to="/page-content-img">
                                                <button className="btn btn-success ml-2"
                                                    onClick={handleSaveToLocalStorage}
                                                >Upload Image</button>
                                            </Link>
                                        </div>
                                    </div>

                                </div>

                                {/* Buttons */}
                                <div className="text-start mt-4">
                                    <Link to="/page-content">
                                        <button className="btn btn-secondary mr-2"
                                            onClick={clearLocalStorage}
                                        >Back</button>
                                    </Link>
                                    <button type="submit" className="btn btn-success">
                                        {editData ? "Update" : "Submit"}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddPageContent;