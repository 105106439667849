import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInterceptor";
import { toast } from "react-toastify";
import { ImageLink } from "../constants";

const AddCounter = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const editData = location.state;
  const [data, setData] = useState({
    position: editData?.position || "",
    name: editData?.name || "",
    title: editData?.title || "",
    subTitle: editData?.subTitle || "",
    number: editData?.number || "",
    image: editData?.image || "",
  });

  const [errors, setErrors] = useState({
    position: "",
    name: "",
    title: "",
    subTitle: "",
    number: "",
    image: "",
  });

  useEffect(() => {
    if (!editData) {
      fetchMaxPosition();
    }
  }, []);

  const fetchMaxPosition = async () => {
    try {
      const response = await axiosInstance.get('/counter/getWithoutPaginationCounter');
      if (response.status === 200) {
        const counters = response.data;
        if (Array.isArray(counters)) {
          const maxPosition = Math.max(...counters.map(counter => counter.position), 0);

          setData(prevData => ({
            ...prevData,
            position: maxPosition + 1
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching banners:", error);
      toast.error("Failed to fetch banner data. Please try again.");
    }
  };

  const handleChange = (e) => {
    const { name, files, value } = e.target;
    if (name === "image") {
      setData({ ...data, [name]: files.length > 0 ? files[0] : null });
    } else {
      setData({ ...data, [name]: value });
    }
    setErrors({ ...errors, [name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let hasErrors = false;

    if (!data.position) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        position: "Sort Order is required",
      }));
      hasErrors = true;
    }
    if (!data?.name) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: "Name is required",
      }));
      hasErrors = true;
    }
    if (!data?.title) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        title: "Title is required",
      }));
      hasErrors = true;
    }
    if (!data?.subTitle) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        subTitle: "Sub title is required",
      }));
      hasErrors = true;
    }
    if (!data?.number) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        number: "Number is required",
      }));
      hasErrors = true;
    }
    if (!data?.image) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        image: "Image is required",
      }));
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("position", data?.position);
      formData.append("name", data?.name);
      formData.append("title", data?.title);
      formData.append("subTitle", data?.subTitle);
      formData.append("number", data?.number);
      if (data?.image instanceof File) {
        formData.append("image", data?.image);
      }
      const response = await axiosInstance.post(
        `/counter/createCounter`,
        formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/counter");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong. Please try again!");
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("position", data?.position);
      formData.append("name", data?.name);
      formData.append("title", data?.title);
      formData.append("subTitle", data?.subTitle);
      formData.append("number", data?.number);
      if (data?.image instanceof File) {
        formData.append("image", data?.image);
      }
      const response = await axiosInstance.put(
        `/counter/updateCounter/${editData?._id}`,
        formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/counter");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="page-header mt-5 mx-4">
          <h3 className="page-title">{editData?._id ? "Edit Counter" : "Add Counter"} </h3>
        </div>
        <div className="col-md-12 grid-margin stretch-card">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <form
                  className="pt-3"
                  onSubmit={editData?._id ? handleUpdate : handleSubmit}
                >
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Position
                        </label>
                        <input
                          type="number"
                          className="form-control form-control-sm"
                          id="position"
                          name="position"
                          placeholder="Enter slider sort order"
                          value={data.position}
                          onChange={handleChange}
                        />
                        {errors.position && <p className="text-danger">{errors.position}</p>}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="name"
                          name="name"
                          placeholder="Enter a name"
                          value={data?.name}
                          onChange={handleChange}
                        />
                        {errors?.name && (
                          <p className="text-danger">{errors?.name}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Title
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="title"
                          name="title"
                          placeholder="Enter title"
                          value={data?.title}
                          onChange={handleChange}
                        />
                        {errors?.title && (
                          <p className="text-danger">{errors?.title}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Sub Title
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          id="subTitle"
                          name="subTitle"
                          placeholder="Enter a subtitle"
                          value={data?.subTitle}
                          onChange={handleChange}
                        />
                        {errors?.subTitle && (
                          <p className="text-danger">{errors?.subTitle}</p>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Number
                        </label>
                        <input
                          type="number"
                          className="form-control form-control-sm"
                          id="number"
                          name="number"
                          placeholder="Enter a number"
                          value={data?.number}
                          onChange={handleChange}
                        />
                        {errors?.number && (
                          <p className="text-danger">{errors?.number}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label
                          htmlFor="exampleInputUsername2"
                          className="col-form-label"
                        >
                          Image
                        </label>
                        <input
                          type="file"
                          className="form-control form-control-sm"
                          id="image"
                          name="image"
                          onChange={handleChange}
                        />
                        {errors?.image && (
                          <p className="text-danger">{errors?.image}</p>
                        )}
                        {data?.image && (
                          <div className="mt-2">
                            {data.image instanceof File ? (
                              <img
                                src={URL.createObjectURL(data.image)}
                                alt="image_preview"
                                width={100}
                              />
                            ) : (
                              <img
                                src={ImageLink(data.image)}
                                alt="image_preview"
                                width={100}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-sm-12">
                      <div className="text-start">
                        <Link to="/counter">
                          <button className="btn btn-secondary mr-2">
                            Back{" "}
                          </button>
                        </Link>
                        <button type="submit" className="btn btn-success">
                          {editData ? "Update" : "Submit"}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCounter;
