import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInterceptor";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { ImageLink } from "../constants";
import Loader from "../Layout/Loader";
import SortArrow from "./SortArrow";
import { sortArrayByKey } from "../js/sorting";
import {
  IoArrowDownCircleOutline,
  IoArrowUpCircleOutline,
} from "react-icons/io5";

const Excellence = () => {
  const [loading, setLoading] = useState(true);
  const [excellenceData, setExcellenceData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    parseInt(sessionStorage.getItem("Excellence")) || 1
  );
  const [sortConfig, setSortConfig] = useState({
    key: "position",
    order: "desc",
  });

  const navigate = useNavigate();
  const perPage = 10;

  const fetchData = async (page = 1) => {
    try {
      const response = await axiosInstance.post(`/excellence/getAllExcellence`, {
        perPage: perPage,
        pageNo: page,
      });
      const { data, totalPages } = response.data;
      setExcellenceData(data);
      setTotalPages(totalPages);
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
        "Something went wrong. Please try again!!"
      );
    } finally {
      setLoading(false);
    }
  };


  const handleStatusToggle = async (id, modelName, isActive) => {
    try {
      const response = await axiosInstance.put(`admin/updateStatus/${id}`, {
        isActive: isActive,
        modelName: modelName,
      });
      if (response.data.isSuccess === true) {
        fetchData(currentPage);
        toast.success(response.data?.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  const updatePosition = async (id, newPosition) => {
    try {
      const itemsToUpdate = [{ id, position: newPosition }];
      const swappedItem = excellenceData.find(
        (item) => item.position === newPosition
      );

      if (swappedItem) {
        itemsToUpdate.push({
          id: swappedItem._id,
          position: excellenceData.find((item) => item._id === id).position,
        });
      }

      await axiosInstance.post("/admin/updatePosition", {
        modelName: "excellence",
        items: itemsToUpdate,
      });

      // Fetch updated data after position update
      fetchData(currentPage);
      toast.success("Position updated successfully");
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  useEffect(() => {
    fetchData(currentPage);
    sessionStorage.setItem("Excellence", currentPage);
  }, [currentPage]);

  const handleSort = (key) => {
    const order =
      sortConfig.key === key && sortConfig.order === "asc" ? "desc" : "asc";
    setSortConfig({ key, order });
  };

  const sortedExcellences = sortArrayByKey(
    excellenceData,
    sortConfig.key,
    sortConfig.order
  );

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this excellence!",
      icon: "success",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        confirmDelete(id);
      }
    });
  };

  const confirmDelete = async (id) => {
    try {
      const response = await axiosInstance.delete(
        `excellence/deleteExcellence/${id}`
      );
      toast.success(response.data.message);
      fetchData(currentPage);
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
        "Something went wrong. Please try again!!"
      );
    }
  };



  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="content-wrapper">
      <div className="page-header mt-5">
        <h3 className="page-title">Excellence</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/add-excellence">
                <button type="button" className="btn btn-inverse-info btn-fw">
                  <i className="icon-plus mx-2 mt-2"></i>Add Excellence
                </button>
              </Link>
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive border rounded p-1">
                <table className="table text-center">
                  <thead>
                    <tr>
                      <th
                        onClick={() => handleSort("position")}
                        className="font-weight-bold sortDiv"
                      >
                        Position
                        <SortArrow
                          isSorted={sortConfig.key === "position"}
                          order={sortConfig.order}
                        />
                      </th>
                      <th className="font-weight-bold">Name</th>
                      <th className="font-weight-bold">Description</th>
                      <th className="font-weight-bold">Icon</th>
                      <th className="font-weight-bold">Active</th>
                      <th className="font-weight-bold">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedExcellences.length === 0 ? (
                      <tr>
                        <td colSpan="7">
                          <h5>No data found.</h5>
                        </td>
                      </tr>
                    ) : (
                      sortedExcellences.map((item, index) => (
                        <tr>
                          <td className="sortingArrow">
                            <span>{item.position}</span>
                            <IoArrowUpCircleOutline
                              className="sorting"
                              onClick={() =>
                                updatePosition(item._id, item.position + 1)
                              }
                            />
                            <IoArrowDownCircleOutline
                              className="sorting"
                              onClick={() =>
                                updatePosition(item._id, item.position - 1)
                              }
                            />
                          </td>
                          <td>{item.name}</td>
                          <td>{item.description}</td>
                          <td>
                            <img
                              src={ImageLink(item.icon)}
                              style={{ borderRadius: "0px", width: "auto" }}
                              alt="excellence"
                            />
                          </td>
                          <td>
                            <div className="custom-control custom-switch">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                name="isActive"
                                id={`isActiveSwitch-${index}`}
                                checked={item.isActive}
                                onChange={() =>
                                  handleStatusToggle(
                                    item._id,
                                    "excellence",
                                    !item.isActive
                                  )
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor={`isActiveSwitch-${index}`}
                              ></label>
                            </div>
                          </td>
                          <td>
                            <div className="template-demo">
                              <button
                                type="button"
                                className="btn btn-outline-primary btn-icon-text btn-sm w-40"
                                onClick={() =>
                                  navigate("/add-excellence", { state: item })
                                }
                              >
                                <i className="icon-pencil btn-icon-append mr-1"></i>{" "}
                                Edit
                              </button>
                              <button
                                type="button"
                                className="btn btn-outline-danger btn-icon-text btn-sm w-35"
                                onClick={() => handleDelete(item._id)}
                              >
                                <i className="icon-trash btn-icon-append"></i>{" "}
                                Delete
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
              <div className="d-flex mt-4 flex-wrap">
                <p className="text-muted">
                  Showing {(currentPage - 1) * perPage + 1} to{" "}
                  {Math.min(currentPage * perPage, excellenceData.length)} of{" "}
                  {totalPages * perPage} entries
                </p>
                <nav className="ml-auto">
                  <ul className="pagination separated pagination-info">
                    <li
                      className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageClick(currentPage - 1)}
                      >
                        <i className="icon-arrow-left" />
                      </button>
                    </li>
                    {Array.from({ length: totalPages }, (_, i) => (
                      <li
                        key={i}
                        className={`page-item ${currentPage === i + 1 ? "active" : ""}`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageClick(i + 1)}
                        >
                          {i + 1}
                        </button>
                      </li>
                    ))}
                    <li
                      className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageClick(currentPage + 1)}
                      >
                        <i className="icon-arrow-right" />
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Excellence;
