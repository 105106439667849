
import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../axiosInterceptor'
import { toast } from 'react-toastify'
import { ImageLink } from '../constants'


const AddPagewisebanner = () => {
    const navigator = useNavigate();
    const location = useLocation();
    const editData = location.state;

    const [data, setData] = useState({
        title: editData?.title || '',
        description: editData?.description || '',
        bannerType: editData?.bannerType || '',
        menu: editData?.menu || '',
        position: editData?.position || '',
        desktopImg: editData?.desktopImg || '',
        mobileImg: editData?.mobileImg || '',
    });

    const [menuData, setMenuData] = useState([])

    const [errors, setErrors] = useState({
        title: '',
        description: '',
        position: '',
        bannerType: '',
        menu: '',
        desktopImg: '',
        mobileImg: '',
    });

    useEffect(() => {
        if (!editData) {
            fetchMaxPosition();
        }
    }, []);

    const fetchMaxPosition = async () => {
        try {
            const response = await axiosInstance.get('/banner/getWithoutPaginationBanner');
            if (response.status === 200) {
                const banners = response.data;
                if (Array.isArray(banners)) {
                    const maxPosition = Math.max(...banners.map(banner => banner.position), 0);

                    setData(prevData => ({
                        ...prevData,
                        position: maxPosition + 1
                    }));
                }
            }
        } catch (error) {
            console.error("Error fetching banners:", error);
            toast.error("Failed to fetch banner data. Please try again.");
        }
    };

    const handleChange = (e) => {
        const { name, files, checked, value } = e.target;

        setData((prevData) => {
            const updatedData = { ...prevData };

            if (name === 'desktopImg' || name === 'mobileImg') {
                updatedData[name] = files.length > 0 ? files[0] : null;
            } else if (name === "isActive") {
                updatedData[name] = checked;
            } else {
                updatedData[name] = value;
            }

            return updatedData;
        });

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasErrors = false;

        if (!data.title.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                title: 'Title is required'
            }));
            hasErrors = true;
        }
        if (!data.description.trim()) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                description: 'Description is required'
            }));
            hasErrors = true;
        }
        if (!data.position) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                position: 'Position is required'
            }));
            hasErrors = true;
        }
        if (!data.desktopImg) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                desktopImg: 'Desktop img is required'
            }));
            hasErrors = true;
        }
        if (!data.mobileImg) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                mobileImg: 'Mobile img is required'
            }));
            hasErrors = true;
        }

        if (hasErrors) {
            return;
        }

        try {
            const formData = new FormData();
            formData.append("title", data.title);
            formData.append("description", data.description);
            formData.append("position", data.position);
            formData.append("menu", data.menu);
            formData.append("bannerType", data.bannerType);

            if (data.desktopImg instanceof File) {
                formData.append("desktopImg", data.desktopImg);
            }
            if (data.mobileImg instanceof File) {
                formData.append("mobileImg", data.mobileImg);
            }

            const response = await axiosInstance.post('banner/createBanner', formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/banner");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            if (error.response) {
                toast.error(error.response.data.message);
            } else {
                toast.error("Something went wrong. Please try again!!");
            }
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("title", data.title);
            formData.append("description", data.description);
            formData.append("position", data.position);
            formData.append("menu", data.menu);
            formData.append("bannerType", data.bannerType);
            if (data.desktopImg instanceof File) {
                formData.append("desktopImg", data.desktopImg);
            }
            if (data.mobileImg instanceof File) {
                formData.append("mobileImg", data.mobileImg);
            }
            const response = await axiosInstance.put(`banner/updateBanner/${editData?._id}`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator("/banner");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again!!');
        }
    };

    const fetchMenuData = async () => {
        try {
            const response = await axiosInstance.get('menu/getMenu');
            const data = response?.data?.data;
            setMenuData(data);

        } catch (error) {
            console.error('Error fetching menu data:', error);
        }
    }
    useEffect(() => {
        fetchMenuData();
    }, [])

    return (
        <>
            <div className="content-wrapper">
                <div className="page-header mt-5 mx-4">
                    <h3 className="page-title">
                        {editData ? "Edit" : "Add"} Page Wise Banner
                    </h3>
                </div>
                <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <form className="pt-3" onSubmit={editData?._id ? handleUpdate : handleSubmit}>
                                        <div className='row'>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputUsername2" className="col-form-label">
                                                        Position
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control form-control-sm"
                                                        id="position"
                                                        name="position"
                                                        placeholder="Enter slider sort order"
                                                        value={data.position}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.position && <p className="text-danger">{errors.position}</p>}
                                                </div>
                                            </div>
                                            {/* Other input fields here */}
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="bannerType" className="col-form-label">
                                                        Banner Type
                                                    </label>
                                                    <select
                                                        className="form-control form-control-sm"
                                                        id="bannerType"
                                                        name="bannerType"
                                                        value={data.bannerType}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="" disabled>Select banner type</option>
                                                        <option value="Image">Image</option>
                                                        <option value="Video">Video</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label htmlFor="menu" className="col-form-label">
                                                        Page Name
                                                    </label>

                                                    <select
                                                        className="form-control form-control-sm text-capitalize"
                                                        id="menu"
                                                        name="menu"
                                                        onChange={handleChange}
                                                        value={data?.menu}
                                                    >
                                                        <option value="" disabled>Select Display banner</option>
                                                        {menuData?.length > 0 && menuData?.map((item) => (
                                                            <React.Fragment key={item?._id}>
                                                                {item?.submenus && item?.submenus?.length > 0 ? (
                                                                    item?.submenus?.map((subitem) => (
                                                                        <option key={subitem?._id} value={subitem?._id} className='text-capitalize'>
                                                                            {item?.menuName + ' >> ' + subitem?.menuName}
                                                                        </option>
                                                                    ))
                                                                ) : (
                                                                    <option key={item?._id} value={item?._id} className='text-capitalize'>
                                                                        {item?.menuName}
                                                                    </option>
                                                                )}
                                                            </React.Fragment>
                                                        ))}
                                                    </select>

                                                </div>
                                            </div>

                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="title"
                                                        className="col-form-label"
                                                    >
                                                        Title
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        id="title"
                                                        name="title"
                                                        placeholder="Enter slider title"
                                                        value={data.title}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.title && <p className="text-danger">{errors.title}</p>}
                                                </div>
                                            </div>
                                            <div className="col-sm-8">
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="Description"
                                                        className="col-form-label"
                                                    >
                                                        Description
                                                    </label>
                                                    <textarea
                                                        type="text"
                                                        className="form-control form-control-sm"
                                                        id="description"
                                                        name="description"
                                                        rows="5"
                                                        placeholder="Enter slider Description"
                                                        value={data.description}
                                                        onChange={handleChange}
                                                    />
                                                    {errors.description && <p className="text-danger">{errors.description}</p>}
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="desktopImg"
                                                        className="col-form-label"
                                                    >
                                                        Desktop File
                                                    </label>
                                                    <input
                                                        type="file"
                                                        className="form-control form-control-sm"
                                                        id="desktopImg"
                                                        name="desktopImg"
                                                        placeholder="Enter slider desktop img"
                                                        onChange={handleChange}
                                                    />
                                                    {errors.desktopImg && <p className="text-danger">{errors.desktopImg}</p>}
                                                </div>

                                                {/* Desktop Image/Video Preview */}
                                                {data.desktopImg && (
                                                    <div>
                                                        {data.bannerType === "Image" ? (
                                                            data.desktopImg instanceof File ? (
                                                                <img
                                                                    src={URL.createObjectURL(data.desktopImg)}
                                                                    alt="desktop_image_preview"
                                                                    className="w-100"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={ImageLink(data.desktopImg)}
                                                                    alt="desktop_image_preview"
                                                                    className="w-100"
                                                                />
                                                            )
                                                        ) : data.desktopImg instanceof File ? (
                                                            <video
                                                                className="w-100 mb-3"
                                                                width="320"
                                                                height="240"
                                                                controls
                                                            >
                                                                <source
                                                                    src={URL.createObjectURL(data.desktopImg)}
                                                                    type={data.desktopImg.type}
                                                                />
                                                            </video>
                                                        ) : (
                                                            <video
                                                                className="w-100 mb-3"
                                                                width="320"
                                                                height="240"
                                                                controls
                                                            >
                                                                <source
                                                                    src={ImageLink(data.desktopImg)}
                                                                    type="video/mp4"
                                                                />
                                                            </video>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label
                                                        htmlFor="mobileImg"
                                                        className="col-form-label"
                                                    >
                                                        Mobile File
                                                    </label>
                                                    <input
                                                        type="file"
                                                        className="form-control form-control-sm"
                                                        id="mobileImg"
                                                        name="mobileImg"
                                                        placeholder="Enter slider mobile img"
                                                        onChange={handleChange}
                                                    />
                                                    {errors.mobileImg && <p className="text-danger">{errors.mobileImg}</p>}
                                                </div>

                                                {/* Mobile Image/Video Preview */}
                                                {data.mobileImg && (
                                                    <div>
                                                        {data.bannerType === "Image" ? (
                                                            data.mobileImg instanceof File ? (
                                                                <img
                                                                    src={URL.createObjectURL(data.mobileImg)}
                                                                    alt="mobile_image_preview"
                                                                    className="w-100"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={ImageLink(data.mobileImg)}
                                                                    alt="mobile_image_preview"
                                                                    className="w-100"
                                                                />
                                                            )
                                                        ) : data.mobileImg instanceof File ? (
                                                            <video
                                                                className="w-100 mb-3"
                                                                width="320"
                                                                height="240"
                                                                controls
                                                            >
                                                                <source
                                                                    src={URL.createObjectURL(data.mobileImg)}
                                                                    type={data.mobileImg.type}
                                                                />
                                                            </video>
                                                        ) : (
                                                            <video
                                                                className="w-100 mb-3"
                                                                width="320"
                                                                height="240"
                                                                controls
                                                            >
                                                                <source
                                                                    src={ImageLink(data.mobileImg)}
                                                                    type="video/mp4"
                                                                />
                                                            </video>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='text-start mt-4'>
                                            <Link to="/banner">
                                                <button className="btn btn-secondary mr-2">
                                                    Back
                                                </button>
                                            </Link>

                                            <button type="submit" className="btn btn-success">
                                                {editData ? "Update" : "Submit"}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default AddPagewisebanner;
