import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../axiosInterceptor";
import { toast } from "react-toastify";

const AddSubCategory = () => {
    const navigator = useNavigate();
    const location = useLocation();
    const editData = location.state;
    const parentId = location.pathname.split("/")[2];

    const [data, setData] = useState({
        parentId: parentId || "",
        name: editData?.name || "",
        position: editData?.position || "",
        category: editData?.category?._id || "",
    });

    const [errors, setErrors] = useState({
        position: "",
        name: "",
    });

    const [pageData, setPageData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await axiosInstance.get(`reportCategory/getcategory`);
            const { data } = response.data;
            setPageData(data);
        } catch (error) {
            toast.error("Something went wrong. Please try again!!");
        }
    };

    // useEffect(() => {
    //     if (!editData) {
    //         fetchMaxPosition();
    //     }
    // }, []);

    // const fetchMaxPosition = async () => {
    //     try {
    //         const response = await axiosInstance.get(
    //             "/reportCategory/getWithoutPaginationCategory"
    //         );
    //         if (response.status === 200) {
    //             const menus = response.data.data;
    //             if (Array.isArray(menus)) {
    //                 const maxPosition = Math.max(
    //                     ...menus.filter(menu => menu.parentId !== null).map(menu => menu.position),
    //                     0
    //                 );

    //                 setData((prevData) => ({
    //                     ...prevData,
    //                     position: maxPosition + 1,
    //                 }));
    //             }
    //         }
    //     } catch (error) {
    //         console.error("Error fetching product:", error);
    //         toast.error("Failed to fetch product data. Please try again.");
    //     }
    // };

    useEffect(() => {
        fetchData();
    }, []);
    const [categoryData, setCategoryData] = useState([]);


    // Fetch category data
    const fetchCategoryData = async () => {
        try {
            const response = await axiosInstance.get(
                "reportCategory/getWithoutPaginationCategory"
            );
            const { data } = response.data;
            if (!Array.isArray(data)) {
                console.error("Category data is not an array:", data);
                return;
            }
            setCategoryData(data);
        } catch (error) {
            console.error("Error fetching category data:", error);
            toast.error("Failed to fetch category data");
        }
    };



    useEffect(() => {
        fetchCategoryData();
    }, []);

    const handleChange = (e) => {
        const { name, checked, value } = e.target;
        if (
            name === "isActive"
        ) {
            setData({ ...data, [name]: checked });
        } else {
            setData({ ...data, [name]: value });
        }
        setErrors({ ...errors, [name]: "" });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasErrors = false;

        if (!data.position) {
            setErrors((prev) => ({ ...prev, position: "Position is required" }));
            hasErrors = true;
        }
        if (!data.name) {
            setErrors((prev) => ({ ...prev, name: "Name is required" }));
            hasErrors = true;
        }


        if (data.menuType === "Category" && !data.category) {
            setErrors((prev) => ({
                ...prev,
                category: "Category selection is required",
            }));
            hasErrors = true;
        }


        if (hasErrors) return;

        try {
            const formData = new FormData();
            if (data.parentId) {
                formData.append("parentId", data.parentId);
            }
            formData.append("name", data.name);
            formData.append("position", data.position);

            if (data.category) formData.append("category", data.category);

            const response = await axiosInstance.post(`reportCategory/createCategory`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator(`/report-category/${parentId}`);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Something went wrong. Please try again!!");
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const formData = new FormData();
            if (data.parentId) {
                formData.append("parentId", data.parentId);
            }
            formData.append("name", data.name);
            formData.append("position", data.position);


            if (data.category) formData.append("category", data.category);

            const response = await axiosInstance.put(`reportCategory/updateCategory/${editData?._id}`, formData);
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator(`/report-category/${parentId}`);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Something went wrong. Please try again!!");
        }
    };

    return (
        <>
            <div className="content-wrapper">
                <div className="page-header mt-5 mx-4">
                    <h3 className="page-title">Add SubCategory</h3>
                </div>
                <div className="col-md-12 grid-margin stretch-card">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <form
                                    className="pt-3"
                                    onSubmit={editData?._id ? handleUpdate : handleSubmit}
                                >
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="position" className="col-form-label">
                                                    Position
                                                </label>
                                                <input
                                                    type="number"
                                                    className="form-control form-control-sm"
                                                    id="position"
                                                    name="position"
                                                    placeholder="Enter a sort order"
                                                    value={data.position}
                                                    onChange={handleChange}
                                                />
                                                {errors.position && (
                                                    <p className="text-danger">{errors.position}</p>
                                                )}
                                            </div>
                                        </div>



                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="category" className="col-form-label">
                                                    Report Category
                                                </label>
                                                <select
                                                    className="form-control form-control-sm"
                                                    id="category"
                                                    name="category"
                                                    value={data.parentId}
                                                    onChange={handleChange}
                                                    disabled
                                                >
                                                    <option value="">
                                                        --- Select Report category ---
                                                    </option>
                                                    {categoryData.map((item) => (
                                                        <option key={item._id} value={item._id}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errors.category && (
                                                    <p className="text-danger">{errors.category}</p>
                                                )}
                                            </div>
                                        </div>




                                        {/* Menu Name */}
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="name" className="col-form-label">
                                                    SubCategory Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-sm"
                                                    id="name"
                                                    name="name"
                                                    placeholder="Enter a menu name"
                                                    value={data.name}
                                                    onChange={handleChange}
                                                />
                                                {errors.name && (
                                                    <p className="text-danger">{errors.name}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {/* Submit and Back Buttons */}
                                    <div className="text-start mt-4">
                                        <Link to={`/report-category/${parentId}`}>
                                            <button className="btn btn-secondary mr-2">Back</button>
                                        </Link>
                                        <button type="submit" className="btn btn-success">
                                            {editData ? "Update" : "Submit"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddSubCategory;
